import { useDownloadEvents } from '@gov-nx/core/events';
import { useBoolean } from '@gov-nx/utils/common';

export const useDownloadBlob = () => {
	const isDownloading = useBoolean();
	const { downloadBlob } = useDownloadEvents();

	return {
		handleDownloadBlob: <T>(getData: () => T) => {
			return {
				download: (onSuccess?: () => void) => {
					isDownloading.setTrue();
					downloadBlob({
						data: getData(),
						onSuccess: () => {
							isDownloading.setFalse();
							if (onSuccess) {
								onSuccess();
							}
						},
						onError: isDownloading.setFalse,
					});
				},
				isDownloading: isDownloading.value,
			};
		},
	};
};
