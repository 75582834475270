import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { useApplicantLoader, useProcessControl } from '@gov-nx/core/hooks';
import { getRequiredDataBoxes } from '@gov-nx/core/service';
import { compose, Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextControls, ServiceContextTypes } from './service.types';
import { filterDataBoxTypes } from './utils';

export const ServiceContext =
	createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({
	children,
	code,
}: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();

	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		mustWaitForProcessing: null,
		downloadLoading: false,
		downloadError: null,
	});

	useApplicantLoader({
		onError: (initialError) => {
			setControls({ initialLoading: false, initialError });
		},
		onLoaded: () => {
			if (controls.initialLoading) {
				setControls({ initialLoading: false });
			}
		},
	});
	const requiredDataBoxes = getRequiredDataBoxes(code);
	const formDefinition = FormInstance({ code, requiredDataBoxes });
	const { downloadBlob } = useDownloadEvents();

	const submitMutation = usePoMutation({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			formDefinition.formReset();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: t('form.messages.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({
			mustWaitForProcessing: null,
			processError: null,
			processLoading: true,
		});
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, setControls, submitMutation]);

	const handleDownload = useCallback(async () => {
		setControls({
			mustWaitForProcessing: null,
			downloadError: null,
			downloadLoading: true,
		});
		return downloadBlob({
			data: prepareSubmitData(formDefinition.formMethods.getValues()),
			onSuccess: () => {
				setControls({ downloadLoading: false });
				formDefinition.formReset();
			},
			onError: (error) => {
				setControls({ downloadError: error, downloadLoading: false });
				formDefinition.formReset();
			},
		});
	}, [downloadBlob, formDefinition, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);
	const onDownload = formDefinition.formMethods.handleSubmit(handleDownload);

	const typOsoby = formDefinition.formMethods.watch('typOsoby');

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				onDownload,
				controls,
				setControls,
				requiredDataBoxes,
				dataBoxTypes: filterDataBoxTypes(requiredDataBoxes, typOsoby),
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const ZadostOOsvedceniDIGUkonuContextInstance = () =>
	useContext(ServiceContext) as ServiceContextTypes;
