import { useDataBoxEvents } from '@gov-nx/core/events';
import {
	useDataBoxCreditLoader,
	useDataBoxLoader,
	useDataBoxUnreadMessages,
} from '@gov-nx/module/data-box';
import { useMyFilesStorage } from '@gov-nx/module/page';

export const DataBoxListener = () => {
	const { refetchDataBoxes } = useDataBoxLoader();
	const myfilesStorage = useMyFilesStorage();
	const { refetchDataBoxCredit } = useDataBoxCreditLoader();
	const { refetchDataBoxUnreadMessages } = useDataBoxUnreadMessages();

	useDataBoxEvents({
		onCreditsRefetch: () => {
			refetchDataBoxCredit();
		},
		onStorageRefetch: () => {
			myfilesStorage.refetch();
		},
		onUnreadMessagesCountsRefetch: async (_, { callback }) => {
			await refetchDataBoxUnreadMessages();
			callback && callback();
		},
		onRefreshList: async (_, { callback }) => {
			await refetchDataBoxes();
			callback && callback();
		},
	});

	return null;
};
