import React, { createContext, useContext } from 'react';
import { fetchSettingsQuery, usePoQueries } from '@gov-nx/api/portal-obcana';
import {
	useCountryCodes,
	useEmailContactDetails,
	usePersonCommunicationData,
	usePersonCommunicationLoader,
	usePhoneContactDetails,
	useUpdateContact,
} from '@gov-nx/core/hooks';
import { useContactHistory } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { ContactDataContext } from './context.types';

const NastaveniKontaktniUdajeContext =
	createContext<Nullable<ContactDataContext>>(null);

interface NastaveniKontaktniUdajeContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function NastaveniKontaktniUdajeContextProvider({
	children,
}: NastaveniKontaktniUdajeContextProviderProps) {
	const communicationData = usePersonCommunicationData();
	const { stringToPhoneShape } = useCountryCodes();
	const { refetchPersonCommunication, isReloading } =
		usePersonCommunicationLoader();

	const countryCodes = useCountryCodes();

	const contactsHistory = useContactHistory();

	const [nuEmailQuery, nuPhoneQuery] = usePoQueries({
		queries: ['NU_EMAIL', 'NU_TELEFON'].map((key) => {
			return {
				queryKey: ['setting-notifications', key],
				queryFn: () => fetchSettingsQuery<string>(key),
				refetchOnWindowFocus: false,
			};
		}),
	});

	const nuEmail =
		!communicationData.emailVerified && !communicationData.unverifiedEmail
			? nuEmailQuery.data?.hodnota
			: undefined;
	const nuPhone =
		!communicationData.phoneVerified && !communicationData.unverifiedPhone
			? nuPhoneQuery.data?.hodnota
			: undefined;

	const updateContact = useUpdateContact({
		source: 'ROB',
		unverifiedEmail: communicationData.unverifiedEmail,
		unverifiedPhone: communicationData.unverifiedPhone,
	});

	const emailContactDetails = useEmailContactDetails({
		updateContact: updateContact.email,
		isEditing: communicationData.control.isEditingEmail,
		refetch: refetchPersonCommunication,
		isReloading,
		unverifiedEmail: communicationData.unverifiedEmail ?? undefined,
		emailVerified: communicationData.emailVerified ?? undefined,
	});

	const phoneContactDetails = usePhoneContactDetails({
		updateContact: updateContact.phone,
		refetch: refetchPersonCommunication,
		isEditing: communicationData.control.isEditingPhone,
		isReloading,
		countryCodes,
		unverifiedPhone: communicationData.unverifiedPhone ?? undefined,
		phoneVerified: communicationData.phoneVerified ?? undefined,
	});

	const handleUseEmail = (email: string) => {
		emailContactDetails.formDefinition.formMethods.setValue('email', email);
		emailContactDetails.onSubmit();
	};

	const handleUsePhone = (phone: string) => {
		const shape = stringToPhoneShape(phone);
		if (shape) {
			phoneContactDetails.formDefinition.formMethods.setValue('telefon', shape);
			phoneContactDetails.onSubmit();
		}
	};

	return (
		<NastaveniKontaktniUdajeContext.Provider
			value={{
				initialState: {
					isLoading:
						!countryCodes.isFetched ||
						nuEmailQuery.isLoading ||
						nuPhoneQuery.isLoading ||
						contactsHistory.isLoading,
					error: countryCodes.error ?? undefined,
				},
				email: emailContactDetails,
				phone: phoneContactDetails,
				nuEmail: nuEmail
					? {
							email: nuEmail,
							isLoading: emailContactDetails.isUpdating,
							useIt: () => handleUseEmail(nuEmail),
					  }
					: undefined,
				nuPhone: nuPhone
					? {
							phone: nuPhone,
							useIt: () => handleUsePhone(nuPhone),
							isLoading: phoneContactDetails.isUpdating,
					  }
					: undefined,
				historicalEmail: contactsHistory.latestEmail
					? {
							email: contactsHistory.latestEmail,
							isLoading: emailContactDetails.isUpdating,
							useIt: () =>
								contactsHistory.latestEmail &&
								handleUseEmail(contactsHistory.latestEmail),
					  }
					: undefined,
				historicalPhone: contactsHistory.latestPhone
					? {
							phone: contactsHistory.latestPhone,
							useIt: () =>
								contactsHistory.latestPhone &&
								handleUsePhone(contactsHistory.latestPhone),
							isLoading: phoneContactDetails.isUpdating,
					  }
					: undefined,
			}}>
			{children}
		</NastaveniKontaktniUdajeContext.Provider>
	);
}

export const useNastaveniKontaktnichUdajuContextInstance =
	(): ContactDataContext =>
		useContext(NastaveniKontaktniUdajeContext) as ContactDataContext;
