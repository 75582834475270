import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import {
	PageCode,
	useKatastrNemovitostiContextInstance,
} from '@gov-nx/module/page';
import {
	Button,
	CommaSpace,
	ContentLayout,
	ErrorStatusBanner,
	LayoutGap,
	Space,
	Tile,
} from '@gov-nx/ui/web';
import { CadastreOfRealEstateFilter } from './CadastreOfRealEstateFilter';
import {
	CadastreOfRealEstateItemsSkeleton,
	CadastreOfRealEstateSkeleton,
} from './CadastreOfRealEstateSkeleton';

export interface CadastreOfRealEstateProps {
	code: PageCode;
}

export const CadastreOfRealEstateView = ({
	code,
}: CadastreOfRealEstateProps) => {
	const { mujKatastrUrl } = useConfiguration();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { realEstates, controls, queryLoading, noRealEstates } =
		useKatastrNemovitostiContextInstance();

	if (controls.initialLoading) {
		return <CadastreOfRealEstateSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<ContentLayout>
			<CadastreOfRealEstateFilter code={code} />
			<LayoutGap gap={6}>
				{queryLoading && <CadastreOfRealEstateItemsSkeleton />}
				<ul className={'gov-list--plain'}>
					{noRealEstates ? (
						<GovMessage
							variant={'primary'}
							className={'mt-6'}>
							{ts('info.nejsou-evidovany-zadne-subjekty')}
							<GovIcon
								type="basic"
								name="info"
								slot="icon"
							/>
						</GovMessage>
					) : (
						realEstates?.map((realEstate, index) => {
							return (
								<Tile
									key={index}
									name={`${realEstate.obec}, ${realEstate.katastr}`}>
									<p className={'!text-secondary-700'}>
										{ts('zaznam.list-vlastnictvi')}
										<Space />
										<a
											href={realEstate.link}
											target={'_blank'}
											className={
												'gov-link inline-flex items-center gap-1 w-auto h-10 text-s text-secondary-500'
											}
											rel="noreferrer">
											<strong>{realEstate.lv}</strong>
											<GovIcon
												slot="right-icon"
												type="basic"
												name="box-arrow-up-right"
												className={'w-3 h-3'}
											/>
										</a>
										{realEstate.podil?.citatel &&
											realEstate.podil?.jmenovatel && (
												<span>
													<CommaSpace />
													{ts('zaznam.podil')}
													<Space />
													<strong>
														{realEstate.podil?.citatel}
														<Space />/<Space />
														{realEstate.podil?.jmenovatel}
													</strong>
												</span>
											)}
									</p>
								</Tile>
							);
						})
					)}
				</ul>
				<Button
					enforceLink={true}
					href={mujKatastrUrl}
					target={'_blank'}
					type="outlined"
					variant="primary"
					wcagLabel={ts('akce.prejit-na-muj-katastr')}>
					{ts('akce.prejit-na-muj-katastr')}
					<GovIcon name="box-arrow-up-right" />
				</Button>
			</LayoutGap>
		</ContentLayout>
	);
};
