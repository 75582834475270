import {
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import {
	ContactDataSection,
	PhoneFormData,
	useContactDetailsTabsFilter,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { ConfirmationPrompt } from '@gov-nx/ui/web';
import { formatPhoneNumber } from '@gov-nx/utils/common';
import { ActionButtons } from './ActionButtons';
import { ContactPhoneForm } from './ContactPhoneForm';
import { ContactDetailSkeleton } from './Skeletons';

interface ContactPhoneViewProps extends ContactDataSection<PhoneFormData> {
	code: PageCode;
}

export const ContactPhoneView = ({
	code,
	isReloading,
	isDeleteConfirmationOpen,
	valueVerified,
	formDefinition,
	onDelete,
	onResendVerification,
	onSubmit,
	onChange,
	isEditing,
	isChanging,
	isUpdating,
	isNeedForVerification,
	isDeleting,
	isVerificationResending,
}: ContactPhoneViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(PageCode['nastaveni']);
	const { activeTab } = useContactDetailsTabsFilter();

	if (isReloading) {
		return <ContactDetailSkeleton />;
	}

	return (
		<div>
			<dl className={'flex flex-col gap-4 pt-2 pb-6'}>
				<dt className={'flex justify-between gap-4'}>
					<div className="flex items-center gap-2">
						<h3 className={'text-l font-bold flex-grow'}>
							{tsn('kontakt.telefonni-cislo')}
						</h3>
						<GovTooltip
							position="bottom"
							size="s"
							icon
							className="inline-flex items-center">
							<GovIcon name="info-circle" />
							<GovTooltipContent>
								<p>
									{activeTab === 'PO'
										? tsn('kontakt.contact-info-tooltip-po')
										: tsn('kontakt.contact-info-tooltip')}
								</p>
							</GovTooltipContent>
						</GovTooltip>
					</div>

					<ActionButtons
						isEditing={isEditing}
						isChanging={isChanging}
						isConfirmationOpen={isDeleteConfirmationOpen}
						isNeedForVerification={isNeedForVerification}
						value={valueVerified}
						strings={{
							buttons: {
								cancel: {
									label: tsn('kontakt.akce.zrusit'),
									wcagLabel: tsn('kontakt.akce.wcag.zrusit-telefon'),
								},
								edit: {
									label: tsn('kontakt.akce.upravit'),
									wcagLabel: tsn('kontakt.akce.wcag.upravit-telefon'),
								},
								delete: {
									wcagLabel: tsn('kontakt.akce.wcag.smazat-telefon'),
								},
							},
						}}
					/>
				</dt>
				<dd>
					{isEditing.value ? (
						<ContactPhoneForm
							code={code}
							onSubmit={onSubmit}
							onChange={onChange}
							isUpdating={isUpdating}
							resendVerification={onResendVerification}
							isVerificationResending={isVerificationResending}
							isNeedForVerification={isNeedForVerification}
							formDefinition={formDefinition}
						/>
					) : valueVerified ? (
						formatPhoneNumber(valueVerified)
					) : (
						<span className="text-secondary-700">
							{tsn(`kontakt.telefon-nevyplneno`)}
						</span>
					)}
				</dd>
			</dl>
			<ConfirmationPrompt
				isOpen={isDeleteConfirmationOpen.value}
				onConfirm={onDelete}
				onCancel={isDeleteConfirmationOpen.setFalse}
				isDisabled={isDeleting}
				isLoading={isDeleting}
				strings={{
					title: tsn('kontakt.akce.smazat.potvrzeni.titulek'),
					buttons: {
						cancel: {
							label: tsn('kontakt.akce.smazat.potvrzeni.nemazat'),
							wcagLabel: tsn('kontakt.akce.smazat.potvrzeni.wcag.nemazat'),
						},
						confirm: {
							label: tsn('kontakt.akce.smazat.potvrzeni.smazat'),
							wcagLabel: tsn('kontakt.akce.smazat.potvrzeni.wcag.smazat'),
						},
					},
				}}>
				<GovIcon
					name="trash"
					slot="icon"
					className={'text-error-400'}
				/>
				{tsn('kontakt.akce.smazat.potvrzeni.otazka')}
			</ConfirmationPrompt>
		</div>
	);
};
