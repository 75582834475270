import { config, useCaptcha } from '@gov-nx/web';
import 'browser-report';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { hasNoSuccessFile, WizardFormStep } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	FormProblemData,
	PodporaFileUploadParams,
	usePodporaContext,
	zpusobPrihlaseniOptions,
} from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormFileUpload,
	FormInput,
	FormRadio,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';

interface PodporaProblemFormProps {
	formMethods: WizardFormStep<FormProblemData>['formDefinition'];
}

export const SupportProblemForm = ({
	formMethods,
}: PodporaProblemFormProps) => {
	const { isLogged, code, controls, isSubmited } = usePodporaContext();
	const { captchaToken } = useCaptcha({ when: !isLogged });
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const [attachmentsLoading, setAttachmentsLoading] = useState(false);

	useEffect(() => {
		const reportResult = (window as any).browserReportSync();
		const version = config.getCurrentVersion();
		const build = config.getCurrentBuild();
		formMethods.formMethods.setValue('technickeInformace', reportResult);
		formMethods.formMethods.setValue('verze', version);
		formMethods.formMethods.setValue('build', build);
		formMethods.formMethods.setValue('captcha', captchaToken);
	}, [captchaToken]);

	return (
		<FormProvider {...formMethods.formMethods}>
			<LayoutGap gap={10}>
				<LayoutGap
					gap={8}
					className={'mt-4'}>
					<FormInput
						field={{
							name: 'souhrn',
							disabled: controls.processLoading,
							placeholder: tsn('formular.placeholders.nazev-problemu'),
						}}
						label={{ children: tsn('formular.pole.nazev-problemu') }}
						group={{ gap: 's' }}
					/>
					<FormInput
						field={{
							name: 'podrobnyPopis',
							disabled: controls.processLoading,
							multiline: true,
							placeholder: tsn('formular.placeholders.popis-problemu'),
						}}
						label={{ children: tsn('formular.pole.popis-problemu') }}
						group={{ gap: 's' }}
					/>
				</LayoutGap>
				<LayoutGap gap={8}>
					<FormRadio
						field={{
							name: 'vyskytChyby',
							disabled: controls.processLoading,
							options: [
								{
									value: 'OPAKOVANE',
									label: { children: tsn('formular.pole.opakovane') },
								},
								{
									value: 'OJEDINELE',
									label: { children: tsn('formular.pole.ojedinele') },
								},
							],
						}}
						label={{
							children: tsn('formular.pole.chyba-se-vyskytuje'),
						}}
						control={{ size: 'm' }}
						group={{ gap: 's' }}
					/>
					<FormRadio
						field={{
							name: 'drivejsiStav',
							disabled: controls.processLoading,
							options: [
								{
									value: 'SPUSTENO_POPRVE',
									label: { children: tsn('formular.pole.spoustim-poprve') },
								},
								{
									value: 'DRIVE_FUNGOVALO',
									label: { children: tsn('formular.pole.drive-fungovala') },
								},
							],
						}}
						label={{
							children: tsn('formular.pole.chyba-ve-funkci'),
						}}
						control={{ size: 'm' }}
						group={{ gap: 's' }}
					/>
					{!isLogged && (
						<FormRadio
							field={{
								name: 'zpusobPrihlaseni',
								disabled: controls.processLoading,
								options: zpusobPrihlaseniOptions.map((option) => {
									return {
										value: option.value,
										label: { children: tsn(option.label.children as string) },
									};
								}),
							}}
							label={{
								children: tsn('formular.pole.zpusob-prihlaseni'),
							}}
							control={{ size: 'm' }}
							group={{ gap: 's' }}
						/>
					)}
				</LayoutGap>
				{formMethods.formMethods.watch('zpusobPrihlaseni') === 'JMENO_HESLO' ? (
					<FormWidthElementLayout>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'prihlaseniUserName',
									disabled: controls.processLoading,
									placeholder: tsn(
										'formular.placeholders.zpusob-prihlaseni-jmeno'
									),
								}}
								label={{
									children: tsn('formular.pole.zpusob-prihlaseni-jmeno'),
								}}
								group={{ gap: 's' }}
							/>
							<FormInput
								field={{
									name: 'prihlaseniEmail',
									disabled: controls.processLoading,
									placeholder: tsn(
										'formular.placeholders.zpusob-prihlaseni-email'
									),
								}}
								label={{
									children: tsn('formular.pole.zpusob-prihlaseni-email'),
								}}
								group={{ gap: 's' }}
							/>
							<FormInput
								field={{
									name: 'prihlaseniTelefon',
									disabled: controls.processLoading,
									placeholder: tsn(
										'formular.placeholders.zpusob-prihlaseni-telefon'
									),
								}}
								label={{
									children: tsn('formular.pole.zpusob-prihlaseni-telefon'),
								}}
								group={{ gap: 's' }}
							/>
						</LayoutGap>
					</FormWidthElementLayout>
				) : null}
				{formMethods.formMethods.watch('zpusobPrihlaseni') === 'DS' ? (
					<FormWidthElementLayout>
						<FormInput
							field={{
								name: 'prihlaseniIdDS',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholders.zpusob-prihlaseni-ds'),
							}}
							label={{ children: tsn('formular.pole.zpusob-prihlaseni-ds') }}
							group={{ gap: 's' }}
						/>
					</FormWidthElementLayout>
				) : null}
				<LayoutGap gap={4}>
					<h4 className={'text-2xl text-secondary-800'}>
						{tsn('formular.pole.priloha')}
					</h4>
					<p>{tsn('formular.pole.soubor-informace')}</p>
					<FormFileUpload
						field={{
							name: 'priloha',
							expanded: true,
							disabled: controls.processLoading,
						}}
						// label={{ children: tsn('formular.pole.file-upload') }}
						isSubmited={isSubmited}
						options={{
							...PodporaFileUploadParams,
							onFilesChanged: (files) =>
								setAttachmentsLoading(hasNoSuccessFile(files)),
						}}
						group={{ gap: 's' }}
					/>
					<p className={'text-s'}>
						{tsn('formular.zprava.nahrat-soubor-popis')}
					</p>
					<PoMarkdownRenderWeb applyClasses={{ paragraph: 'text-s' }}>
						{tsn('formular.zprava.chraneno-recaptcha')}
					</PoMarkdownRenderWeb>
				</LayoutGap>
				<ButtonGroupLayout>
					<Button
						loading={controls.processLoading}
						wcagLabel={tsn('form.wcag.pokracovat') as string}
						disabled={attachmentsLoading || controls.processLoading}
						nativeType="submit"
						variant="primary"
						size="l"
						type="solid">
						{tsn('formular.tlacitko.pokracovat')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
		</FormProvider>
	);
};
