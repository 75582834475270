import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	authLogoutQuery,
	isUnauthorizedResponse,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useConfiguration } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { useAppLogout, useAuthStore } from '@gov-nx/store/portal-obcana';
import { isNiaLoginType } from '@gov-nx/utils/common';
import { routeResolver } from '../Router/routeResolver';

export const useUserLogout = () => {
	const { poServerUrl } = useConfiguration();
	const { getUserLoginType } = useAuthStore();
	const loginType = getUserLoginType();
	const { logout } = useAppLogout();
	const navigate = useNavigate();

	const niaLogoutMutation = usePoMutation(
		{
			mutationKey: ['nia-logout'],
			mutationFn: authLogoutQuery,
			retry: false,
			onSettled: (niaResponse) => {
				if (niaResponse?.url) {
					logout();
					navigate(routeResolver(PageCode.odhlaseni));
					window.location.href = poServerUrl + niaResponse.url;
				} else {
					logout();
					window.location.href = routeResolver(PageCode.odhlaseni);
				}
			},
		},
		{
			errorIgnoreFilter: isUnauthorizedResponse,
		}
	);

	const onUserLogout = useCallback(async () => {
		if (isNiaLoginType(loginType)) {
			return niaLogoutMutation.mutate();
		}
		logout();
		window.location.href = routeResolver(PageCode.odhlaseni);
	}, [loginType, logout, niaLogoutMutation]);

	return { onUserLogout };
};
