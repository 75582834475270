import { useEffect } from 'react';
import { robPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { usePersonStore } from '@gov-nx/store/portal-obcana';

interface SupportDataLoader {
	isAuth: boolean;
	onLoaded?: () => void;
	onError?: (error: GovError) => void;
}

export const usePoSupportDataLoad = (props?: SupportDataLoader) => {
	const { communication, individual: individualPerson } = usePersonStore();
	const { setIndividualPersonData } = usePersonStore();

	usePoQuery({
		queryKey: ['osoby', 'fyzicke'],
		queryFn: robPersonQuery,
		onSuccess: (data) => setIndividualPersonData(data),
		onError: async (err) => {
			if (props?.onError) {
				return props.onError(new GovError(err.message));
			}
		},
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: props?.isAuth,
	});

	useEffect(() => {
		if (props?.onLoaded && individualPerson && communication) {
			props.onLoaded();
		}
	}, [individualPerson, communication]);

	return { individualPerson, communication };
};
