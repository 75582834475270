import { DataboxAdditionalSettingsFormData } from '../nastaveni/useDataboxAdditionalSettings';

// Tenhle enum nam nekdo poslal z backendu
enum TypNotifikaceDS {
	Nic = 0,
	Povoleno = 1,
	SMS = 2,
	EmailInformace = 4,
	EmailMetadata = 8,
	EmailZFO = 16,
	EmailPrilohy = 32,
}

export const decompose = (
	input: number,
	start = 64,
	iterations: number[] = []
): number[] => {
	if (input === 0) {
		return [0];
	}
	if (input === 1) {
		return [1];
	}

	const value = input - start;
	if (value === 0) {
		return [...iterations, start, 0];
	}
	if (value === 1) {
		return [...iterations, start, 1];
	}

	if (value < 0) {
		return decompose(input, start / 2, iterations);
	}
	return decompose(value, start / 2, [...iterations, start]);
};

const sum = (numbers: number[]): number =>
	numbers.reduce((value, all) => {
		return value + all;
	}, 0);

const includes =
	<T>(array1: T[]) =>
	(array2: T[]) =>
		array1.some((value) => array2.includes(value));

export const getSettings = (input: number): NotificationCheckboxesValues => {
	const numbers = decompose(input);
	const check = includes(numbers);
	if (check([TypNotifikaceDS.Nic]) || !check([TypNotifikaceDS.Povoleno])) {
		return {
			enabledSms: false,
			enabledEmail: false,
			enabledEmailAttachment: false,
		};
	}

	return {
		enabledSms: check([TypNotifikaceDS.SMS]),
		enabledEmail: check([
			TypNotifikaceDS.EmailPrilohy,
			TypNotifikaceDS.EmailZFO,
			TypNotifikaceDS.EmailMetadata,
			TypNotifikaceDS.EmailInformace,
		]),
		enabledEmailAttachment: check([TypNotifikaceDS.EmailPrilohy]),
	};
};

const getValues = (values: NotificationCheckboxesValues): number[] => {
	if (values.enabledSms && !values.enabledEmail) {
		return [TypNotifikaceDS.SMS, TypNotifikaceDS.Povoleno];
	}

	if (values.enabledEmail && !values.enabledSms) {
		return values.enabledEmailAttachment
			? [
					TypNotifikaceDS.EmailPrilohy,
					TypNotifikaceDS.EmailZFO,
					TypNotifikaceDS.Povoleno,
			  ]
			: [TypNotifikaceDS.EmailMetadata, TypNotifikaceDS.Povoleno];
	}

	if (values.enabledEmail && values.enabledSms) {
		return values.enabledEmailAttachment
			? [
					TypNotifikaceDS.EmailPrilohy,
					TypNotifikaceDS.EmailZFO,
					TypNotifikaceDS.SMS,
					TypNotifikaceDS.Povoleno,
			  ]
			: [
					TypNotifikaceDS.EmailMetadata,
					TypNotifikaceDS.SMS,
					TypNotifikaceDS.Povoleno,
			  ];
	}

	return [TypNotifikaceDS.Nic];
};

export interface NotificationCheckboxesValues {
	enabledSms: boolean;
	enabledEmail: boolean;
	enabledEmailAttachment: boolean;
}

export const useNotificationSettingsValues = () => {
	return {
		compose: (values: NotificationCheckboxesValues): string => {
			const numbers = getValues(values);
			return sum(numbers).toString();
		},
		decompose: (input: string = '0'): NotificationCheckboxesValues => {
			const num = parseInt(input, 10);
			const settings = getSettings(num);

			return settings;
		},
		additionalSettingsCompose: (
			values: DataboxAdditionalSettingsFormData
		): string => {
			if (values.emailEnabled) {
				return sum([
					TypNotifikaceDS.EmailMetadata,
					TypNotifikaceDS.Povoleno,
				]).toString();
			}

			return TypNotifikaceDS.Nic.toString();
		},
	};
};
