import { GovIcon } from '@gov-design-system-ce/react';
import { PoMarkdownRenderWeb } from '@gov-nx/component/web';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useDataBoxConnectBanner } from '@gov-nx/core/hooks';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { WebDataBoxConnectBannerProps } from '@gov-nx/ui/types';
import { Button } from '../Button/Button';
import { ButtonGroupLayout } from '../Layout/ButtonGroupLayout';

export const DataBoxConnectBanner = ({
	requiredTypes,
	translationPrefix,
	dataBoxAccessOverride,
}: WebDataBoxConnectBannerProps) => {
	const {
		possibleToConnect,
		possibleToCreate,
		possibleToConnectOrCreate,
		listToCreateTypes,
	} = {
		...useDataBoxAccess({ requiredTypes }),
		...dataBoxAccessOverride,
	};
	const { connect, create } = useDataBoxEvents();

	const { strings, showSecondaryButton } = useDataBoxConnectBanner({
		possibleToConnect,
		possibleToCreate,
		requiredTypes,
		translationPrefix,
		listToCreateTypes,
	});

	if (!possibleToConnectOrCreate) {
		return null;
	}

	const primaryButton = (
		<Button
			variant={'warning'}
			type={'solid'}
			onClick={() =>
				possibleToConnect ? connect({ requiredTypes }) : create()
			}
			wcagLabel={strings.primaryButton.wcag}>
			{strings.primaryButton.content}
		</Button>
	);

	const secondaryButton = (
		<Button
			variant={'primary'}
			type={'outlined'}
			inverse
			onClick={create}
			wcagLabel={strings.secondaryButton.wcag}>
			{strings.secondaryButton.content}
		</Button>
	);

	return (
		<div
			role="status"
			className={'bg-gradient-to-t to-primary-700 from-primary-600 rounded'}>
			<div
				className={
					'flex flex-col gap-4 items-start p-4 text-neutral-white [ md:flex-row md:gap-6 md:p-8 ]'
				}>
				<GovIcon
					className={'w-12 h-12 text-warning-400'}
					type={'basic'}
					name={'datovka'}></GovIcon>
				<div className={'flex flex-col gap-3 flex-1'}>
					<h3 className={'text-l font-bold'}>{strings.title}</h3>
					<PoMarkdownRenderWeb applyClasses={{ paragraph: 'text-s' }}>
						{strings.content}
					</PoMarkdownRenderWeb>
					{showSecondaryButton && (
						<ButtonGroupLayout>
							{primaryButton}
							{secondaryButton}
						</ButtonGroupLayout>
					)}
				</div>
				{!showSecondaryButton && primaryButton}
			</div>
		</div>
	);
};
