import { GovChip, GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useMojeSouborySeznamContext } from '@gov-nx/module/page';
import { BadgeNav } from '@gov-nx/ui/web';

export const Badges = () => {
	const { badges, myFilesFilters, code, isSelectedRecycleBin } =
		useMojeSouborySeznamContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<BadgeNav
			className={cx(isSelectedRecycleBin ? 'min-h-[56px]' : '')}
			wcagLabel={tsn('formular.pole.zdroj')}
			moreBadges>
			{badges.map((badge, index) => {
				return (
					<li
						key={index}
						className={'whitespace-nowrap [ before:content-none ]'}>
						<GovChip
							wcagLabel={`${tsn('formular.pole.zdroj')}: ${tsn(
								badge.nameCode
							)}`}
							variant="primary"
							size="m"
							tag={'button'}
							inverse={myFilesFilters.filter.selectedBadgeIndex !== index}
							onGov-click={() => myFilesFilters.selectBadge(index)}>
							<GovIcon
								slot="left-icon"
								name={badge.icon}></GovIcon>
							{tsn(badge.nameCode)}
						</GovChip>
					</li>
				);
			})}
		</BadgeNav>
	);
};
