import { create } from 'zustand';
import {
	OsobyKomunikacniUdajeDto,
	OsobyPodnikatelSeznamDto,
	OsobyFyzickaOsobaDto,
} from '@gov-nx/api/portal-obcana';
import { propEq } from '@gov-nx/core/types';
import { PersonState } from './store.types';

const initialState = {
	communication: undefined,
	business: undefined,
	individual: undefined,
};

export const usePersonStore = create<PersonState>()((set, get) => ({
	...initialState,

	setPersonCommunicationData: (communication: OsobyKomunikacniUdajeDto) =>
		set({ communication }),
	setBusinessPersonData: (business: OsobyPodnikatelSeznamDto) =>
		set({ business }),
	setIndividualPersonData: (individual: OsobyFyzickaOsobaDto) =>
		set({ individual }),
	personReset: () => set({ ...initialState }),

	poBusinessDataPO: () =>
		get().business?.seznam?.filter(propEq('typ', 'PO')) ?? [],
	poBusinessDataPFO: () => get().business?.seznam?.find(propEq('typ', 'PFO')),
	isPersonAdult: () => {
		const age = get().individual?.vek;
		if (age && age >= 18) return true;
		return false;
	},
	poCommunicationEmail: () => get().communication?.email ?? null,
	poCommunicationVerifyEmail: () => get().communication?.emailOvereni ?? null,
	poCommunicationPhone: () => get().communication?.telefonniCislo ?? null,
	poCommunicationVerifyPhone: () =>
		get().communication?.telefonniCisloOvereni ?? null,
	poCommunicationAddress: () => get().individual?.adresaPobytu ?? null,
}));
