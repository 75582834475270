import { GovSkeleton } from '@gov-design-system-ce/react';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const RegisterSVListSkeleton = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={16}>
			{Array.from({ length: 3 }).map((_, index) => (
				<LayoutGap
					key={index}
					gap={2}>
					<GovSkeleton
						height={'30px'}
						width={generateRandomNumber(20, 60) + '%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'24px'}
						width={generateRandomNumber(40, 80) + '%'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</LayoutGap>
			))}
		</LayoutGap>
	);
};

export const RegisterSVListAndFilterSkeleton = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout>
			<LayoutGap gap={3}>
				<div className={'flex gap-4 pb-3 border-b border-secondary-300'}>
					<GovSkeleton
						height={'40px'}
						width={'330px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'40px'}
						width={'40px'}
						wcagLabel={t('loading.wcag.processing')}
						className={'ml-auto'}
					/>
				</div>
				<div className={'flex gap-4 py-3 mb-6'}>
					<GovSkeleton
						height={'40px'}
						width={'77px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<GovSkeleton
						height={'40px'}
						width={'76px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</div>
				<RegisterSVListSkeleton />
			</LayoutGap>
		</ContentLayout>
	);
};
