import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { ReactNode } from 'react';
import { NativeLabelProps, WebLabelProps } from './FormLabel';
import { WebMessagesProps } from './FormMessage';

export enum RadioBooleanOption {
	no = 'no',
	yes = 'yes',
}

export interface WebFormRadioProps extends JSX.GovFormRadio {
	label: WebLabelProps;
}

export interface WebRadioOption extends Omit<WebFormRadioProps, 'name'> {
	value: string;
	children?: ReactNode;
}

export interface WebRadioField {
	name: string;
	options: WebRadioOption[];
	disabled?: boolean;
}

export interface WebRadioProps {
	field: WebRadioField;
	label: WebLabelProps;
	labelUnder?: ReactNode;
	control?: JSX.GovFormControl;
	group?: JSX.GovFormGroup;
	messages?: WebMessagesProps;
}

export type NativeRadioOption = {
	value: string;
	label: NativeLabelProps;
	disabled?: boolean;
	onPress?: (id: string) => void;
	selected?: boolean;
	size?: 's' | 'm' | 'l' | 'xl';
	variant?: 'primary' | 'secondary' | 'warning' | 'error' | 'success';
	containerStyle?: object;
	description?: string;
	descriptionStyle?: object;
	borderSize?: number;
	children?: ReactNode;
};

export type NativeRadioProps = {
	layout?: 'row' | 'column';
	field: {
		name: string;
		disabled?: boolean;
		options: NativeRadioOption[];
		variant?: NativeRadioOption['variant'];
		size?: NativeRadioOption['size'];
	};
	label: NativeLabelProps;
	labelUnder?: ReactNode;
};
