import { FormProvider } from 'react-hook-form';
import { DefaultUploadParameters, hasNoSuccessFile } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useMojeSouboryNahratContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormFileUpload,
	FormInput,
	FormWidthElementLayout,
	LayoutGap,
	Modal,
} from '@gov-nx/ui/web';
import { useBoolean } from '@gov-nx/utils/common';
import { PoFormWeb } from '../../../Form/PoForm';

export const MyFilesUploadForm = () => {
	const {
		code,
		isUploadModalOpened,
		formDefinition,
		onSubmit,
		isUploading,
		isSubmitted,
	} = useMojeSouboryNahratContext();
	const hasNotFinishedUploads = useBoolean(false);

	const { getLocalizeCurried, ts } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const formShouldBeReset = isSubmitted.value || !isUploadModalOpened.value;

	return (
		<FormProvider {...formDefinition.formMethods}>
			<Modal
				blockClose={isUploading || hasNotFinishedUploads.value}
				label={ts(
					LocalizeNameSpaceTypes.Form,
					'nahrani-souboru.nahrat-soubor-ze-zarizeni'
				)}
				open={isUploadModalOpened.value}
				onGov-close={isUploadModalOpened.setFalse}>
				<PoFormWeb
					formDefinition={formDefinition}
					onSubmit={onSubmit}>
					<LayoutGap gap={4}>
						<FormFileUpload
							field={{ name: 'files', expanded: true, disabled: isUploading }}
							options={{
								...DefaultUploadParameters,
								onFilesChanged: (files) =>
									hasNotFinishedUploads.setValue(hasNoSuccessFile(files)),
							}}
							isSubmited={formShouldBeReset}
						/>
						<FormWidthElementLayout type="l">
							<FormInput
								field={{
									name: 'name',
									variant: 'secondary',
								}}
								label={{
									children: tsn('formular.pole.nazev-dokumentu'),
								}}
								control={{
									size: 'l',
								}}
							/>
						</FormWidthElementLayout>
						<ButtonGroupLayout>
							<Button
								wcagLabel={tsn('akce.nahrat-soubor')}
								nativeType={'submit'}
								variant={'primary'}
								loading={isUploading}
								disabled={isUploading || hasNotFinishedUploads.value}
								size={'m'}
								type={'solid'}>
								{tsn('akce.nahrat-soubor')}
							</Button>
						</ButtonGroupLayout>
					</LayoutGap>
				</PoFormWeb>
			</Modal>
		</FormProvider>
	);
};
