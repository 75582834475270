import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const RegisterSVDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);

	return (
		<LayoutGap>
			<ContentLayout>
				<LayoutGap gap={3}>
					<div
						className={'flex gap-4 overflow-hidden py-3 mb-6 [ md:flex-wrap ]'}>
						<GovSkeleton
							height={'40px'}
							width={'73px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'65px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'143px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'112px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'209px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'57px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'93px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</div>
					<DataTable responsive>
						<tbody>
							{Array.from(Array(15))
								.fill(null)
								.map((_, index) => {
									return (
										<tr key={index}>
											<td className={'md:!w-1/2'}>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(20, 70) + '%'}
													wcagLabel={t('loading.wcag.processing')}
												/>
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(20, 70) + '%'}
													wcagLabel={t('loading.wcag.processing')}
													className={'flex flex-grow '}
												/>
											</td>
										</tr>
									);
								})}
						</tbody>
					</DataTable>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<LayoutGap gap={3}>
					<div
						className={'flex gap-4 overflow-hidden py-3 mb-6 [ md:flex-wrap ]'}>
						<GovSkeleton
							height={'40px'}
							width={'191px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'190px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'40px'}
							width={'198px'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</div>
					<DataTable responsive>
						<tbody>
							<tr>
								<td className={'md:!w-1/2'}>
									<GovSkeleton
										height={'19px'}
										width={generateRandomNumber(20, 60) + '%'}
										wcagLabel={t('loading.wcag.processing')}
									/>
								</td>
								<td>
									<GovSkeleton
										height={'19px'}
										width={generateRandomNumber(20, 60) + '%'}
										wcagLabel={t('loading.wcag.processing')}
										className={'flex flex-grow '}
									/>
								</td>
							</tr>
						</tbody>
					</DataTable>
				</LayoutGap>
			</ContentLayout>
			<ContentLayout>
				<LayoutGap>
					<GovSkeleton
						height={'30px'}
						width={'180px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
					<div>
						<GovSkeleton
							height={'20px'}
							width={'100%'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'20px'}
							width={'100%'}
							wcagLabel={t('loading.wcag.processing')}
						/>
						<GovSkeleton
							height={'20px'}
							width={'30%'}
							wcagLabel={t('loading.wcag.processing')}
						/>
					</div>
					<GovSkeleton
						height={'40px'}
						width={'290px'}
						wcagLabel={t('loading.wcag.processing')}
					/>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
