import { GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useIdentity } from '@gov-nx/auth/common';
import { useAppContext } from '@gov-nx/core/app';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';
import { useScrollPosition, useWindowDimensions } from '@gov-nx/utils/web';
import { LanguageSwitchLink } from '../LanguageSwitch/LanguageSwitch';

const MOBILE_WIDTH_MAX = 480;
const HEADER_HEIGHT = 62;

export interface MainHeaderProps {
	navigation?: React.ReactNode;
	className?: string;
}

export const MainHeader = ({ navigation, className }: MainHeaderProps) => {
	const { isAuthenticated } = useIdentity();

	const headerRef = useRef<HTMLElement>(null);
	const { width } = useWindowDimensions();
	const { scrollY, direction } = useScrollPosition();

	const staysAtPlace = width < MOBILE_WIDTH_MAX && direction === 'down';
	const opacity =
		staysAtPlace && direction === 'down' && scrollY > HEADER_HEIGHT ? 0 : 1;

	const { appError } = useAppContext();
	const { environment } = useConfigurationStore();
	const signalAppBreakingError = environment === 'lcl' && appError;

	const { getLocalizeCurried, t } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Global);

	return (
		<header
			ref={headerRef}
			id="main-header"
			style={{ opacity, transition: 'opacity 0.3s ease-in' }}
			className={cx(
				'top-0 left-0 w-full z-200 border-b border-secondary-500',
				signalAppBreakingError ? 'bg-error-400' : 'bg-neutral-white',
				staysAtPlace ? 'absolute' : 'fixed',
				className,
				'[ print:hidden ]'
			)}>
			<div
				className={
					'gov-container flex flex-row justify-between items-center pt-2.5 pb-2.5 min-h-[60px] [ sm:pt-2.5 sm:pb-2.5 ][ lg:pr-[55px] ]'
				}>
				<Link
					to="/"
					className={'flex items-center'}
					aria-label={tn('odkaz.zpet-na-hp')}>
					<GovIcon
						name={'logo'}
						type={'colored'}
						slot="icon"
						className={'text-primary-600 w-[86px] h-9'}
						aria-label={tn('logo.portal-obcana')}></GovIcon>
				</Link>
				{isAuthenticated ? navigation : <LanguageSwitchLink />}
			</div>
		</header>
	);
};
