import React from 'react';
import { useMessageEvents } from '@gov-nx/core/events';

export const ToastMessageListener = () => {
	useMessageEvents({
		onToastMessage: (_, { options, content }) => {
			const previousToast = document.querySelector('gov-toast');
			if (previousToast) {
				previousToast.remove();
			}

			const toast = document.createElement('gov-toast');
			toast.setAttribute('variant', options.variant as string);
			toast.setAttribute('type', options.type ? options.type : 'solid');
			toast.setAttribute(
				'gravity',
				options.gravity ? options.gravity : 'bottom'
			);
			toast.setAttribute(
				'position',
				options.position ? options.position : 'center'
			);
			toast.setAttribute('time', options.time ? String(options.time) : '10000');
			toast.innerHTML = content;

			if (options.icon && options.icon.name && options.icon.type) {
				const icon = document.createElement('gov-icon');
				icon.setAttribute('name', options.icon.name);
				icon.setAttribute('type', options.icon.type);
				icon.setAttribute('slot', 'icon');
				toast.appendChild(icon);
			}

			document.body.appendChild(toast);
		},
	});
	return <></>;
};
