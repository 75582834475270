import { isFileUploadItemWeb, WizardFormStep } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	FormProblemData,
	PageCode,
	usePodporaContext,
} from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, LayoutGap } from '@gov-nx/ui/web';
import { formatPhoneNumber } from '@gov-nx/utils/common';

interface PodporaRecapitulationProps {
	code: PageCode;
	isProcessing: boolean;
	formMethods: WizardFormStep<FormProblemData>['formDefinition'];
}

export const SupportRecapitulation = ({
	code,
	isProcessing,
	formMethods,
}: PodporaRecapitulationProps) => {
	const { wizard, isLogged, formData } = usePodporaContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<LayoutGap gap={8}>
			<LayoutGap gap={4}>
				<div className={'flex gap-2'}>
					<h4 className={'text-xl'}>{tsn('formular.pole.udaje-uzivatele')}</h4>
					<Button
						onClick={() => wizard.openStep(0)}
						wcagLabel={tsn('formular.tlacitko.wcag.upravit-prijemce-udaju')}
						variant="primary"
						type="base"
						size="s">
						{tsn('formular.tlacitko.upravit')}
					</Button>
				</div>
				<dl className={'flex flex-col gap-2'}>
					{!isLogged && (
						<>
							<div>
								<dt className={'inline mr-1 font-bold'}>
									{tsn('formular.pole.rekapitulace-jmeno')}:
								</dt>
								<dd className={'inline'}>{formData.jmeno}</dd>
							</div>
							<div>
								<dt className={'inline mr-1 font-bold'}>
									{tsn('formular.pole.rekapitulace-prijmeni')}:
								</dt>
								<dd className={'inline'}>{formData.prijmeni}</dd>
							</div>
						</>
					)}
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-email')}:
						</dt>
						<dd className={'inline'}>{formData.email}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-telefon')}:
						</dt>
						<dd className={'inline'}>
							{formData.telefon
								? formatPhoneNumber(formData.telefon)
								: tsn('formular.pole.rekapitulace-telefon-neuvedeno')}
						</dd>
					</div>
				</dl>
			</LayoutGap>
			<LayoutGap gap={4}>
				<div className={'flex gap-2'}>
					<h4 className={'text-xl'}>
						{tsn('formular.pole.rekapitulace-popis-problemu')}
					</h4>
					<Button
						onClick={() => wizard.openStep(1)}
						wcagLabel={tsn(
							'formular.tlacitko.wcag.upravit-rozsah-poskytnutych-udaju'
						)}
						variant="primary"
						type="base"
						size="s">
						{tsn('formular.tlacitko.upravit')}
					</Button>
				</div>
				<dl className={'flex flex-col gap-2'}>
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-nazev-problemu')}:
						</dt>
						<dd className={'inline'}>{formData.souhrn}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-detailni-popis')}
						</dt>
						<dd className={'inline'}>{formData.podrobnyPopis}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-vyskyt-chyby')}:
						</dt>
						<dd className={'inline'}>
							{tsn('formular.pole.seznam.' + formData.vyskytChyby)}
						</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-drivejsi-stav')}:
						</dt>
						<dd className={'inline'}>
							{tsn('formular.pole.seznam.' + formData.drivejsiStav)}
						</dd>
					</div>
					{formData.zpusobPrihlaseni && (
						<div>
							<dt className={'inline mr-1 font-bold'}>
								{tsn('formular.pole.rekapitulace-zpusob-prihlaseni-nazev')}:
							</dt>
							<dd className={'inline'}>
								{tsn(
									'formular.pole.rekapitulace-zpusob-prihlaseni.' +
										formData.zpusobPrihlaseni
								)}
							</dd>
						</div>
					)}
					{formMethods.formMethods.watch('zpusobPrihlaseni') ===
						'JMENO_HESLO' && (
						<>
							<div>
								<dt className={'inline mr-1 font-bold'}>
									{tsn('formular.pole.rekapitulace-zpusob-prihlaseni-username')}
									:
								</dt>
								<dd className={'inline'}>{formData.prihlaseniUserName}</dd>
							</div>

							<div>
								<dt className={'inline mr-1 font-bold'}>
									{tsn('formular.pole.rekapitulace-zpusob-prihlaseni-email')}:
								</dt>
								<dd className={'inline'}>{formData.prihlaseniEmail}</dd>
							</div>

							<div>
								<dt className={'inline mr-1 font-bold'}>
									{tsn('formular.pole.rekapitulace-zpusob-prihlaseni-telefon')}:
								</dt>
								<dd className={'inline'}>
									{formData.prihlaseniTelefon
										? formatPhoneNumber(formData.prihlaseniTelefon)
										: '-'}
								</dd>
							</div>
						</>
					)}
					{formMethods.formMethods.watch('zpusobPrihlaseni') === 'DS' && (
						<div>
							<dt className={'inline mr-1 font-bold'}>
								{tsn('formular.pole.rekapitulace-zpusob-prihlaseni-id-DS')}:
							</dt>
							<dd className={'inline'}>{formData.prihlaseniIdDS}</dd>
						</div>
					)}

					<div>
						<dt className={'inline mr-1 font-bold'}>
							{tsn('formular.pole.rekapitulace-priloha')}:
						</dt>
						<dd className={'inline'}>
							{formData['priloha'] && formData['priloha'].length
								? formData['priloha']
										.filter(isFileUploadItemWeb)
										.map((attachment) => (
											<Button
												key={attachment.id}
												variant={'primary'}
												type={'link'}
												onClick={() =>
													window.open(URL.createObjectURL(attachment.file))
												}
												wcagLabel={attachment.name}>
												{attachment.name}
											</Button>
										))
								: tsn('formular.pole.rekapitulace-bez-prilohy')}
						</dd>
					</div>
				</dl>
			</LayoutGap>
			<ButtonGroupLayout>
				<Button
					onClick={wizard.submit}
					loading={isProcessing}
					wcagLabel={tsn('formular.wcag.odeslat') as string}
					disabled={isProcessing}
					nativeType="submit"
					variant="primary"
					size="l"
					type="solid">
					{tsn('formular.tlacitko.odeslat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
