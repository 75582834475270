import { useWatch } from 'react-hook-form';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { Button, Prompt } from '@gov-nx/ui/web';

export const LowCreditPrompt = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	const { getDataBoxCredit } = useDataBoxStore();
	const { recipient, form, onRecipientRemove } = useDataBoxMessageFormContext();
	const { datovaSchrankaDobitiKredituUrl } = useConfiguration();
	const senderId = useWatch({ control: form.control }).senderId;
	const currentCredit = senderId ? getDataBoxCredit(senderId) : null;
	const open = Boolean(
		recipient &&
			!recipient?.typSchranky?.startsWith('OVM') &&
			currentCredit !== null &&
			currentCredit < 10
	);

	return (
		<Prompt
			label={tn('nova-zprava.je-potreba-dobit-kredit')}
			open={!!open}
			onGov-close={onRecipientRemove}>
			<p>{tn('nova-zprava.je-potreba-dobit-kredit-vysvetleni')}</p>

			<Button
				variant={'primary'}
				nativeType={'button'}
				type={'base'}
				onClick={onRecipientRemove}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.zrusit')}>
				{tn('nova-zprava.nepridavat-adresata')}
			</Button>

			<Button
				href={datovaSchrankaDobitiKredituUrl.replace(
					'{{DS_ID}}',
					senderId as string
				)}
				enforceLink={true}
				variant={'primary'}
				type={'solid'}
				slot={'actions'}
				wcagLabel={tn('kredit.wcag.prejit')}>
				{tn('nova-zprava.prejit-na-dobiti')}
			</Button>
		</Prompt>
	);
};
