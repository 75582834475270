import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { MarkdownRender } from '@gov-nx/component/web';
import {
	LocalizeNameSpaceTypes,
	usePoFormContext,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import {
	DataBoxOnlyOptionProps,
	WebDataBoxDetailsProps,
	WebDataBoxSelectProps,
} from '@gov-nx/ui/types';
import { prepareDataBoxOptions } from '@gov-nx/utils/common';
import { ToggleButton } from '../../Button/ToggleButton';
import { FormWidthElementLayout } from '../../Layout/FormWidthElementLayout';
import { LayoutGap } from '../../Layout/LayoutGap';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';

const DataBoxDetails = (props: WebDataBoxDetailsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);

	return (
		<ToggleButton
			id={props.toggleButtonID}
			wcagHideLabel={ts('data-box.wcag.skryt-doplnkove-udaje')}
			wcagShowLabel={ts('data-box.wcag.zobrazit-doplnkove-udaje')}
			showLabel={ts('data-box.labels.zobrazit-doplnkove-udaje')}
			hideLabel={ts('data-box.labels.skryt-doplnkove-udaje')}>
			<LayoutGap gap={4}>
				<MarkdownRender
					content={
						props.titleCopy ?? ts('data-box.messages.doplnujici-udaje-obsag')
					}
				/>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.fileNumberField?.name ?? 'naseCisloJednaci',
							placeholder:
								props.fileNumberField?.labelCopy ??
								ts('data-box.placeholders.cislo-jednaci') ??
								undefined,
							disabled: props.fileNumberField?.disabled || props.disabled,
						}}
						label={{
							children:
								props.fileNumberField?.placeholderCopy ??
								ts('data-box.fields.cislo-jednaci'),
						}}
					/>
				</FormWidthElementLayout>
				<FormWidthElementLayout>
					<FormInput
						field={{
							name: props.referenceNumberField?.name ?? 'naseSpisovaZnacka',
							placeholder:
								props.referenceNumberField?.placeholderCopy ??
								ts('data-box.placeholders.spisova-znacka'),
							disabled: props.referenceNumberField?.disabled || props.disabled,
						}}
						label={{
							children:
								props.referenceNumberField?.labelCopy ??
								ts('data-box.fields.spisova-znacka'),
						}}
					/>
				</FormWidthElementLayout>
			</LayoutGap>
		</ToggleButton>
	);
};

const DataBoxOnlyOption = ({
	field,
	label,
	dataBox,
}: DataBoxOnlyOptionProps) => {
	const { required } = usePoFormContext().propsFromSchema(field.name);

	return (
		<div className={'flex flex-col gap-2 mb-1'}>
			<span className={'gov-color--secondary-700'}>
				{label.children}
				{required ? <span className="gov-color--error-500"> *</span> : null}
			</span>
			<span className={'gov-color--secondary-800 font-semibold'}>
				{dataBoxLabel(dataBox)}
			</span>
		</div>
	);
};

export const DataBoxSelect = ({ types, ...props }: WebDataBoxSelectProps) => {
	const { setValue, getValues } = useFormContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const value = getValues(props.field.name);

	const { getConnectedDataboxesListByTypes } = useDataBoxStore();
	const dataBoxes = getConnectedDataboxesListByTypes(types);

	const dataBoxesOptions = prepareDataBoxOptions(dataBoxes);

	useEffect(() => {
		if (!value && dataBoxesOptions.length > 0) {
			setValue(props.field.name, dataBoxesOptions[0].value);
		}
	}, [value, dataBoxesOptions]);

	return (
		<fieldset className={'flex flex-col gap-2'}>
			<legend className={'sr-only'}>
				{ts('data-box.wcag.vyber-datove-schranky')}
			</legend>
			{dataBoxes.length === 1 ? (
				<DataBoxOnlyOption
					field={props.field}
					label={props.label}
					dataBox={dataBoxes[0]}
				/>
			) : (
				<FormWidthElementLayout type={'xl'}>
					<FormSelect
						{...props}
						field={{ ...props.field, options: dataBoxesOptions }}
					/>
				</FormWidthElementLayout>
			)}
			{!props.isDetailDisabled && (
				<DataBoxDetails
					{...props.details}
					disabled={props.field.disabled}
				/>
			)}
		</fieldset>
	);
};
