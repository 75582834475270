import {
	CommonSettingKey,
	NastaveniApiActualDto,
	UserSetting,
} from '@gov-nx/api/portal-obcana';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { getValues, Optional } from '@gov-nx/core/types';
import { isValidAppLanguage } from '../Localization/l10n-utils';

export const mapToUserSetting = (
	setting: NastaveniApiActualDto
): Optional<UserSetting> => {
	if (setting.klic === CommonSettingKey.Favorites) {
		if (!Array.isArray(setting.hodnota)) {
			console.warn(
				`Setting [${
					setting.klic
				}] expected to be an array, got ${typeof setting.hodnota}.`
			);
			return;
		}
		return { key: setting.klic, value: setting.hodnota };
	}

	if (setting.klic === CommonSettingKey.Language) {
		if (!isValidAppLanguage(setting.hodnota)) {
			console.warn(
				`Setting [${setting.klic}] expected to be one of the: [${getValues(
					LocalizationLanguage
				).join(', ')}] got ${JSON.stringify(setting.hodnota)}.`
			);
			return;
		}

		return { key: setting.klic, value: setting.hodnota };
	}
};
