import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
	sendLoggedSupportQuery,
	sendNotLoggedSupportQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	WizardFormStep,
	usePoSupportDataLoad,
	useProcessControl,
	useWizard,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useAuthStore } from '@gov-nx/store/portal-obcana';
import { PageCode } from '../definitions/codes';
import {
	FormPersonInstance,
	FormProblemInstance,
	prepareSubmitData,
} from './FormDefinitions';
import { FormData, FormPersonData, SupportContextTypes } from './context.types';

const PodporaContext = createContext<Nullable<SupportContextTypes>>(null);

interface PodporaContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function PodporaContextProvider({
	children,
	code,
}: PodporaContextProviderProps) {
	const { controls, setControls } = useProcessControl({
		initialLoading: false,
	});
	const navigate = useNavigate();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessageSuccess } = useMessageEvents();
	const [isSubmited, setIsSubmited] = useState(false);
	const { isLoggedIn } = useAuthStore();
	const isLogged = isLoggedIn();

	useEffect(() => {
		if (isLogged) {
			setControls({ initialLoading: true });
		}
	}, []);

	const { individualPerson, communication } = usePoSupportDataLoad({
		isAuth: isLogged,
		onError: (initialError) => setControls({ initialError }),
		onLoaded: () => setControls({ initialLoading: false }),
	});

	const submitMutation = usePoMutation({
		mutationFn: (formData: FormData) => {
			const prepared = prepareSubmitData(formData);
			if (!isLogged) {
				return sendNotLoggedSupportQuery(prepared);
			}
			return sendLoggedSupportQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.odeslana'));
			setControls({ processLoading: false });
			wizard.resetForms();
			setIsSubmited(true);
			navigate(-1);
		},
	});

	const onSubmit = useCallback(
		async (values: FormData) => {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[setControls, submitMutation]
	);

	const wizard = useWizard({
		steps: [
			FormPersonInstance({ code, communication, individualPerson }),
			FormProblemInstance({ code, isLogged }),
			{},
		],
		onSubmit,
	});

	const formData = wizard.formData;
	const personForm = wizard.step(
		0
	) as unknown as WizardFormStep<FormPersonData>;

	useEffect(() => {
		if (individualPerson && communication) {
			personForm.formDefinition.formMethods.setValue(
				'jmeno',
				individualPerson.jmeno ?? ''
			);
			personForm.formDefinition.formMethods.setValue(
				'prijmeni',
				individualPerson.prijmeni ?? ''
			);
		}
	}, [individualPerson, communication, wizard.resetForms]);

	return (
		<PodporaContext.Provider
			value={{
				code,
				controls,
				individualPerson,
				isLogged,
				wizard,
				formData,
				setControls,
				isSubmited,
			}}>
			{children}
		</PodporaContext.Provider>
	);
}

export const usePodporaContext = (): SupportContextTypes =>
	useContext(PodporaContext) as SupportContextTypes;
