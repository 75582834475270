import { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
	newDataBoxesMessageQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useMessageEvents } from '@gov-nx/core/events';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { compose } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { getAutocompleteShape } from '@gov-nx/utils/common';
import { useDataBoxMessageDetailContext } from '../providers/MessageDetailContext';
import { DataBoxMessageFormInputs } from '../providers/MessageFormContext.types';

export const useNewMessageForm = (
	setControls: (params: Partial<ProcessControlCore>) => void,
	onMessageSent?: (dataBoxId: string) => void
) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { getConnectedDataBoxesList, lastOpenedDatabox: lastOpenedDataBoxId } =
		useDataBoxStore();
	const dataBoxes = getConnectedDataBoxesList();
	const messageContext = useDataBoxMessageDetailContext();
	const { toastMessage } = useMessageEvents();
	const message = messageContext?.message;
	const [dataLoading, setDataLoading] = useState(
		messageContext?.messageId ? true : false
	);
	const formSchema = yup
		.object<FormSchemaShape<DataBoxMessageFormInputs>>({
			senderId: yup.string().required(tn('nova-zprava.chyba.odesilatel')),
			recipientAutocomplete: getAutocompleteShape({
				requiredMessage: tn('nova-zprava.chyba.prijemce'),
			}),
			subject: yup.string().required(tn('nova-zprava.chyba.predmet')),
			message: yup
				.string()
				.max(5000, tn('nova-zprava.chyba.zprava-delka'))
				.test(
					'test empty string',
					tn('nova-zprava.chyba.zprava-zadny-text'),
					(value) => {
						const contentLength = value?.length || 0;
						const trimmedLength = value?.trim().length || 0;

						return (
							(contentLength > 0 && trimmedLength > 0) || contentLength === 0
						);
					}
				)
				.when(['documents', 'files'], {
					is: (documents: number[], files: string[]) =>
						documents.length === 0 && files.length === 0,
					then: (schema) => schema.required(tn('nova-zprava.chyba.zprava')),
				}),
			toTheHandsOf: yup.string(),
			intoTheirOwnHands: yup.boolean(),
			senderIdentification: yup.boolean(),
			naseCisloJednaci: yup.string(),
			naseSpisovaZnacka: yup.string(),
			vaseCisloJednaci: yup.string(),
			vaseSpisovaZnacka: yup.string(),
			documents: yup.array().of(yup.number()),
			files: yup.array().of(yup.object()),
			temporary: yup.object(),
		})
		.required();

	const defaultValues: DataBoxMessageFormInputs = {
		message: undefined,
		subject: undefined,
		senderId: lastOpenedDataBoxId,
		recipientAutocomplete: undefined,
		files: [],
		documents: [],
		temporary: {
			additionalData: {},
			files: [],
		},
	};

	const formMethods = usePoForm<DataBoxMessageFormInputs>({
		mode: 'onTouched',
		reValidateMode: 'onBlur',
		formSchema,
		defaultValues: defaultValues,
	});
	const formDefinition = getFormDefinition({ formMethods, formSchema });

	const prepareSubmitData = (data: DataBoxMessageFormInputs) => {
		const requestBody = new FormData();

		if (data.senderId) {
			requestBody.append('odesilatelId', data.senderId);
		}
		if (data.recipientAutocomplete?.selected?.datovaSchrankaId) {
			requestBody.append(
				'prijemceId',
				data.recipientAutocomplete.selected.datovaSchrankaId
			);
		}
		if (data.subject) {
			requestBody.append('vec', data.subject);
		}

		if (data.message) {
			requestBody.append('text', data.message);
		}

		if (data.toTheHandsOf) {
			requestBody.append('kRukam', data.toTheHandsOf);
		}

		if (data.intoTheirOwnHands) {
			requestBody.append('doVlastnichRukou', data.intoTheirOwnHands.toString());
		}

		if (data.senderIdentification) {
			requestBody.append(
				'pridatIdentifikaciOdesilatele',
				data.senderIdentification.toString()
			);
		}

		if (data.naseCisloJednaci) {
			requestBody.append('naseCisloJednaci', data.naseCisloJednaci);
		}

		if (data.naseSpisovaZnacka) {
			requestBody.append('naseSpisovaZnacka', data.naseSpisovaZnacka);
		}

		if (data.vaseCisloJednaci) {
			requestBody.append('vaseCisloJednaci', data.vaseCisloJednaci);
		}

		if (data.vaseSpisovaZnacka) {
			requestBody.append('vaseSpisovaZnacka', data.vaseSpisovaZnacka);
		}

		data.documents?.forEach((documentId) =>
			requestBody.append('prilohyZDokumentu[]', documentId.toString())
		);

		data.files?.forEach((file) =>
			requestBody.append('soubory[]', file.fileId ?? '')
		);

		return requestBody;
	};

	const { creditsRefetch } = useDataBoxEvents();

	const submitMutation = usePoMutation({
		mutationFn: compose(newDataBoxesMessageQuery, prepareSubmitData),
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			creditsRefetch();
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					time: 3000,
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: 'Zpráva odeslána',
			});

			const senderId = formMethods.getValues('senderId');
			if (onMessageSent && senderId) {
				// redirect back to inbox
				onMessageSent(senderId);
			}

			formMethods.reset();
		},
	});

	useEffect(() => {
		if (message && dataBoxes.length) {
			formMethods.setValue(
				'senderId',
				dataBoxes.find((item) => item.datovaSchrankaId === message.prijemceId)
					?.datovaSchrankaId ??
					dataBoxes[0]?.datovaSchrankaId ??
					''
			);
			formMethods.setValue('recipientAutocomplete', {
				value: '',
				selected: messageContext.senderDataBox,
			});
			formMethods.setValue(
				'subject',
				message.vec?.startsWith('Re:') ? message.vec : `Re: ${message.vec}`
			);
			formMethods.setValue('naseCisloJednaci', message.naseCisloJednaci ?? '');
			formMethods.setValue(
				'naseSpisovaZnacka',
				message.naseSpisovaZnacka ?? ''
			);
			formMethods.setValue('vaseCisloJednaci', message.vaseCisloJednaci ?? '');
			formMethods.setValue(
				'vaseSpisovaZnacka',
				message.vaseSpisovaZnacka ?? ''
			);
			setDataLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message, dataBoxes]);

	return {
		form: formMethods,
		formSchema,
		formDefinition,
		submitMutation,
		dataLoading,
	};
};
