import { GovIcon } from '@gov-design-system-ce/react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	useMojeSouboryNahratContext,
	useMojeSouborySeznamContext,
} from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { Storage } from './Storage';

export const UploadSection = () => {
	const { code, storage } = useMojeSouborySeznamContext();
	const { isUploadModalOpened } = useMojeSouboryNahratContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<section>
			<Storage />
			<Button
				wcagLabel={tsn('akce.nahrat-soubor')}
				variant="primary"
				type="solid"
				className={'mb-6'}
				onClick={isUploadModalOpened.setTrue}>
				<GovIcon
					name="upload"
					slot="left-icon"
					className={'flex-grow-0 flex-shrink-0 basis-6'}></GovIcon>
				{tsn('akce.nahrat-soubor')}
			</Button>
		</section>
	);
};
