import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
	fetchDataBoxesCreditsQuery,
	usePoQuery,
	usePoResponseError,
} from '@gov-nx/api/portal-obcana';
import { useAppContext } from '@gov-nx/core/app';
import { isFulfilled, isRejected } from '@gov-nx/core/types';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';

const queryKey = ['data-box-credit'];

export const useDataBoxCreditLoader = () => {
	const { setAppError } = useAppContext();
	const { setDataBoxesCredit, getConnectedDataBoxesList } = useDataBoxStore();
	const dataBoxes = getConnectedDataBoxesList();

	const { handleError } = usePoResponseError();

	const queryClient = useQueryClient();
	const query = usePoQuery({
		queryKey,
		queryFn: async () =>
			fetchDataBoxesCreditsQuery(
				dataBoxes.map((dataBox) => dataBox.datovaSchrankaId as string)
			),
		onSuccess: (data) => {
			setDataBoxesCredit(
				data
					?.filter(isFulfilled)
					?.map((response) => response.value)
					?.reduce(
						(
							acc: Record<string, number>,
							{
								data: { kredit },
								config: {
									params: { datovaSchrankaId },
								},
							}
						) => {
							acc[datovaSchrankaId as string] = kredit as number;
							return acc;
						},
						{}
					) ?? {}
			);

			const errorResponse = data.find(isRejected);

			if (errorResponse) {
				const error = errorResponse.reason;
				handleError(error);

				setAppError(error as Error);
			}
		},
		enabled: false,
		retry: 1,
	});

	const initDataBoxCredit = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetchDataBoxCredit = useCallback(async (): Promise<void> => {
		await queryClient.invalidateQueries({ queryKey });

		await query.refetch();
	}, [query, queryClient]);

	return { initDataBoxCredit, refetchDataBoxCredit };
};
