import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	DatovkaApiType,
	DatovkaNastaveniArchivaceValue,
	disconnectDataBoxQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useArchivingSettingsQuery } from '@gov-nx/api/portal-obcana';
import { UiBlockingGroupContext } from '@gov-nx/component/common';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { useDataBoxLoader } from '@gov-nx/module/data-box';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { PageCode } from '../../../definitions/codes';
import {
	FormInstance,
	DataboxAdditionalSettingsFormData,
} from './FormDefinitions';

interface DataboxAdditionalSettingsContext {
	databoxId: string;
	databox?: DatovkaApiType;
	controls: ReturnType<typeof useProcessControl>['controls'];
	formDefinitions: FormDefinition<DataboxAdditionalSettingsFormData>;
	openDisconnectConfirmation: () => void;
	closeDisconnectConfirmation: () => void;
	doDisconnect: () => void;
	isDisconnectConfirmationOpen: boolean;
	isAutomaticArchiveEnabled: boolean;
}

export const DalsiNastaveniDatovkyContext =
	createContext<DataboxAdditionalSettingsContext | null>(null);

interface DalsiNastaveniDatovkyContextProviderProps {
	databoxId: string;
	children: ReactNode;
}

export const DalsiNastaveniDatovkyContextProvider = ({
	databoxId,
	children,
}: DalsiNastaveniDatovkyContextProviderProps) => {
	const { controls, setControls } = useProcessControl();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tds = getLocalizeCurried(PageCode['datove-schranky']);
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { removeDataBox, getDataBoxById } = useDataBoxStore();
	const { refetchDataBoxes } = useDataBoxLoader();
	const uiBlocking = useContext(UiBlockingGroupContext);
	const [isDisconnectConfirmationOpen, setDisconnectConfirmationOpen] =
		useState(false);
	const databox = getDataBoxById(databoxId);

	const archivingQuery = useArchivingSettingsQuery(databoxId);
	const formDefinitions = FormInstance({ databoxId });

	useEffect(() => {
		if (controls.initialLoading) {
			return;
		}
		const subscription = formDefinitions.formMethods.watch(
			(value, { name }) => {
				if (name && name.endsWith('.shouldArchiveAutomatically')) {
					const [dataBoxId] = name.split('.');
					const isChecked =
						value[dataBoxId]?.shouldArchiveAutomatically ?? false;

					setControls({ processLoading: true });
					archivingQuery.mutate(
						{
							value: isChecked
								? DatovkaNastaveniArchivaceValue.DataBoxMessages
								: null,
						},
						{
							onError: (error) => {
								setControls({ processError: error });
							},
							onSettled: () => {
								setControls({ processLoading: false });
							},
						}
					);
				}
				if (name && name.endsWith('.shouldArchiveLargeMessagesAutomatically')) {
					const [dataBoxId] = name.split('.');
					const isLargeMessagesChecked =
						value[dataBoxId]?.shouldArchiveLargeMessagesAutomatically ?? false;
					const isMessagesChecked =
						value[dataBoxId]?.shouldArchiveAutomatically ?? false;

					setControls({ processLoading: true });
					archivingQuery.mutate(
						{
							value: isLargeMessagesChecked
								? DatovkaNastaveniArchivaceValue.LargeDataboxMessage
								: isMessagesChecked
								? DatovkaNastaveniArchivaceValue.DataBoxMessages
								: null,
						},
						{
							onError: (error) => {
								setControls({ processError: error });
							},
							onSettled: () => {
								setControls({ processLoading: false });
							},
						}
					);
				}
			}
		);

		return () => subscription.unsubscribe();
	}, [controls.initialLoading, formDefinitions.formMethods]);

	useEffect(() => {
		if (controls.initialLoading && archivingQuery.data) {
			const { klic, hodnota } = archivingQuery.data || {};
			if (klic && hodnota === DatovkaNastaveniArchivaceValue.DataBoxMessages) {
				formDefinitions.formMethods.setValue(
					`${databoxId}.shouldArchiveAutomatically`,
					true
				);
			}
			if (
				klic &&
				hodnota === DatovkaNastaveniArchivaceValue.LargeDataboxMessage
			) {
				formDefinitions.formMethods.setValue(
					`${databoxId}.shouldArchiveAutomatically`,
					true
				);
				formDefinitions.formMethods.setValue(
					`${databoxId}.shouldArchiveLargeMessagesAutomatically`,
					true
				);
			}

			setControls({ initialLoading: false });
		}
	}, [
		archivingQuery.data,
		controls.initialLoading,
		databoxId,
		formDefinitions.formMethods,
		setControls,
	]);

	const { mutate: disconnectDatabox } = usePoMutation({
		mutationKey: ['datovka-disconnect'],
		mutationFn: () => disconnectDataBoxQuery(databoxId),
		onSuccess: () => {
			removeDataBox(databoxId);
			toastMessageSuccess(tds('odpojeni.datova-schranka-odpojena'));
			refetchDataBoxes();
		},
		onError: (error) => {
			setControls({ processError: error });
			uiBlocking?.unblockEditation();
			toastMessageError();
		},
		onSettled: () => {
			setControls({ processLoading: false });
		},
	});

	const openDisconnectConfirmation = () => {
		setDisconnectConfirmationOpen(true);
	};

	const closeDisconnectConfirmation = () => {
		setDisconnectConfirmationOpen(false);
	};

	const doDisconnect = () => {
		setDisconnectConfirmationOpen(false);
		uiBlocking?.blockEditation();
		setControls({ processLoading: true });
		setTimeout(() => {
			disconnectDatabox();
		}, 0);
	};

	const contextValue = {
		databoxId,
		databox,
		controls,
		formDefinitions,
		openDisconnectConfirmation,
		isDisconnectConfirmationOpen,
		closeDisconnectConfirmation,
		doDisconnect,
		isAutomaticArchiveEnabled: formDefinitions.formMethods.watch(
			`${databoxId}.shouldArchiveAutomatically`
		),
	};

	return (
		<DalsiNastaveniDatovkyContext.Provider value={contextValue}>
			{children}
		</DalsiNastaveniDatovkyContext.Provider>
	);
};
