import { GovChip, GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuDetailContext } from '@gov-nx/module/page';
import {
	BadgeNav,
	Button,
	ContentLayout,
	ErrorStatusBanner,
} from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { ConsentManagementDetailSkeleton } from './ConsentManagementDetailSkeleton';
import { ConsentManagementInfo } from './ConsentManagementInfo';
import { ConsentManagementUsers } from './ConsentManagementUsers';

interface ConsentManagementDetailProps {
	code: PageCode;
}

export const ConsentManagementDetailView = ({
	code,
}: ConsentManagementDetailProps) => {
	const {
		controls,
		setControls,
		filteredDetail,
		handleSubmit,
		refInfoBadges,
		view,
	} = useSpravaSouhlasuDetailContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const getContent = () => {
		if (controls.initialLoading) {
			return <ConsentManagementDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		return (
			<>
				<ConsentManagementInfo code={code} />
				<ConsentManagementUsers code={code} />
				<ContentLayout customClasses={'!pt-4'}>
					<BadgeNav>
						{refInfoBadges.map((badge, index: number) => (
							<li key={index}>
								<GovChip
									variant={'primary'}
									size="m"
									tag="button"
									inverse={!badge.isActive}
									wcagLabel={`${tsn('formular.wcag.filtrovat')} ${badge.name}`}
									onGov-click={() => badge.viewFunction()}>
									{badge.name}
								</GovChip>
							</li>
						))}
					</BadgeNav>
					{view.referencniUdaje && 'referencni udaje placeholder'}
					{view.nereferencniUdaje && 'nereferencni udaje placeholder'}
					<GovModal
						open={controls.displayModal}
						label={tsn('modal.pole.odvolani-souhlasu')}
						onGov-close={() => setControls({ displayModal: false })}>
						{tsn('modal.pole.potvrzeni-odvolani')}
						<div className={'flex justify-end pt-8'}>
							<Button
								onClick={() => handleSubmit()}
								loading={controls.modalProcessLoading}
								disabled={controls.modalProcessLoading}
								variant="primary"
								type="solid"
								nativeType="button"
								size="m"
								wcagLabel={tsn('tlacitko.akce.odvolat')}>
								{tsn('tlacitko.akce.odvolat')}
							</Button>
						</div>
					</GovModal>
				</ContentLayout>
			</>
		);
	};

	return (
		<CmsPageRender
			code={code}
			override={{ name: filteredDetail?.nazevOsoby }}
			showDescription={false}>
			{getContent()}
		</CmsPageRender>
	);
};
