import { lazy } from 'react';
import { ServiceCode } from './definitions/codes';
import { NeverejnyVypisROSContextInstance } from './neverejny-vypis-z-ROS/Context';
import { useNeverejnyVypisZZRContext } from './neverejny-vypis-z-ZR/Context';
import { PodaniZadostiInformaceContextInstance } from './podani-zadosti-o-informace/Context';
import { PotvrzeniStudiuContextInstance } from './potvrzeni-o-studiu/Context';
import { TrestniOznameniContextInstance } from './trestni-oznameni/Context';
import { VerejnyVypisROBContextInstance } from './verejny-vypis-z-ROS/Context';
import { VolicskyPrukazDetailContextInstance } from './volicsky-prukaz/detail/Context';
import { VolicskyPrukazSeznamContextInstance } from './volicsky-prukaz/seznam/Context';
import { VypisBodovehoHodnoceniRidiceContextInstance } from './vypis-bodoveho-hodnoceni-ridice/Context';
import { VypisUdajuZROBContextInstance } from './vypis-udaju-z-ROB/Context';
import { VypisVyuzitiUdajuZROBContextInstance } from './vypis-vyuziti-udaju-z-ROB/Context';
import { VypisVyuzitiUdajuZROSContextInstance } from './vypis-vyuziti-udaju-z-ROS/Context';
import { VypisInsolvencnihoRejstrikuContextInstance } from './vypis-z-IR/Context';
import { VypisRejstrikuTrestuFOContextInstance } from './vypis-z-rejstriku-trestu-FO/Context';
import { VypisRejstrikuTrestuPOContextInstance } from './vypis-z-rejstriku-trestu-PO/Context';
import { VypisZVerejnehoRejstrikuContextInstance } from './vypis-z-verejneho-rejstriku/Context';
import { VypisZivnostenskehoRejstrikuContextInstance } from './vypis-z-zivnostenskeho-rejstriku/Context';
import { VypisSeznamuDodavateluContextInstance } from './vypis-ze-seznamu-KD/Context';
import { ZadostOOsvedceniDIGUkonuContextInstance } from './zadost-o-osvedceni-DIG-ukonu/Context';
import { ZadostOPoskytnutiUdajuContextInstance } from './zadost-o-poskytovani-udaju/Context';

export {
	ZadostOPoskytnutiUdajuReklamaceContextProvider,
	ZadostOPoskytnutiUdajuReklamaceContextInstance,
} from './zadost-o-poskytovani-udaju/reklamace/Context';

export type { NahledyUdajuResponse } from './vypis-bodoveho-hodnoceni-ridice/service.types';
export type {
	FormDataStep2,
	ScopeHelperField,
} from './zadost-o-poskytovani-udaju/service.types';
export type {
	TrestniOznameniFormDataStep1,
	TrestniOznameniFormDataStep2,
	TrestniOznameniFormDataStep3,
	TrestniOznameniFormDataStep4,
	TrestniOznameniFormDataStep5,
	TrestniOznameniFormDataStep6,
	TrestniOznameniFormData,
} from './trestni-oznameni/service.types';
export {
	TrestniOznameniGender,
	TrestniOznameniGroupTarget,
} from './trestni-oznameni/service.types';
export * from './definitions/ServiceMap';
export * from './definitions/services';
export * from './definitions/codes';
export * from './potvrzeni-o-studiu/Lists';
export * from './volicsky-prukaz/detail/utils';

const ServiceProviderList = {
	[ServiceCode['vypis-z-rejstriku-trestu-FO']]: lazy(() =>
		import('./vypis-z-rejstriku-trestu-FO/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-z-rejstriku-trestu-PO']]: lazy(() =>
		import('./vypis-z-rejstriku-trestu-PO/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['verejny-vypis-z-ROS']]: lazy(() =>
		import('./verejny-vypis-z-ROS/Context').then(
			({ ServiceContextProvider }) => ({ default: ServiceContextProvider })
		)
	),
	[ServiceCode['neverejny-vypis-z-ROS']]: lazy(() =>
		import('./neverejny-vypis-z-ROS/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-z-zivnostenskeho-rejstriku']]: lazy(() =>
		import('./vypis-z-zivnostenskeho-rejstriku/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['neverejny-vypis-z-ZR']]: lazy(() =>
		import('./neverejny-vypis-z-ZR/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-vyuziti-udaju-z-ROB']]: lazy(() =>
		import('./vypis-vyuziti-udaju-z-ROB/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-vyuziti-udaju-z-ROS']]: lazy(() =>
		import('./vypis-vyuziti-udaju-z-ROS/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-ze-seznamu-KD']]: lazy(() =>
		import('./vypis-ze-seznamu-KD/Context').then(
			({ ServiceContextProvider }) => ({ default: ServiceContextProvider })
		)
	),
	[ServiceCode['vypis-z-IR']]: lazy(() =>
		import('./vypis-z-IR/Context').then(({ ServiceContextProvider }) => ({
			default: ServiceContextProvider,
		}))
	),
	[ServiceCode['zadost-o-osvedceni-DIG-ukonu']]: lazy(() =>
		import('./zadost-o-osvedceni-DIG-ukonu/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-bodoveho-hodnoceni-ridice']]: lazy(() =>
		import('./vypis-bodoveho-hodnoceni-ridice/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['vypis-z-verejneho-rejstriku']]: lazy(() =>
		import('./vypis-z-verejneho-rejstriku/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['podani-zadosti-o-informace']]: lazy(() =>
		import('./podani-zadosti-o-informace/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['potvrzeni-o-studiu']]: lazy(() =>
		import('./potvrzeni-o-studiu/Context').then(
			({ ServiceContextProvider }) => ({ default: ServiceContextProvider })
		)
	),
	[ServiceCode['vypis-udaju-z-ROB']]: lazy(() =>
		import('./vypis-udaju-z-ROB/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['zadost-o-poskytovani-udaju']]: lazy(() =>
		import('./zadost-o-poskytovani-udaju/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
	[ServiceCode['trestni-oznameni']]: lazy(() =>
		import('./trestni-oznameni/Context').then(({ ServiceContextProvider }) => ({
			default: ServiceContextProvider,
		}))
	),
	[ServiceCode['volicsky-prukaz']]: lazy(() =>
		import('./volicsky-prukaz/seznam/Context').then(
			({ ServiceContextProvider }) => ({
				default: ServiceContextProvider,
			})
		)
	),
};

export const VolicskyPrukazDetailContextProvider = lazy(() =>
	import('./volicsky-prukaz/detail/Context').then(
		({ VolicskyPrukazDetailContextProvider }) => ({
			default: VolicskyPrukazDetailContextProvider,
		})
	)
);

export const getServiceContextProvider = (code: ServiceCode) => {
	return ServiceProviderList[code];
};

export const getVypisRejstrikuTrestuFOContext =
	VypisRejstrikuTrestuFOContextInstance;
export const getVypisRejstrikuTrestuPOContext =
	VypisRejstrikuTrestuPOContextInstance;
export const getVerejnyVypisROBContext = VerejnyVypisROBContextInstance;
export const getNeverejnyVypisROSContext = NeverejnyVypisROSContextInstance;
export const getVypisZivnostenskehoRejstrikuContext =
	VypisZivnostenskehoRejstrikuContextInstance;
export const getNeverejnyVypisZZRContext = useNeverejnyVypisZZRContext;
export const getVypisVyuzitiUdajuZROBContextInstance =
	VypisVyuzitiUdajuZROBContextInstance;
export const getVypisVyuzitiUdajuZROSContextInstance =
	VypisVyuzitiUdajuZROSContextInstance;
export const getVypisSeznamuDodavateluContextInstance =
	VypisSeznamuDodavateluContextInstance;
export const getVypisInsolvencnihoRejstrikuContextInstance =
	VypisInsolvencnihoRejstrikuContextInstance;
export const getZadostOOsvedceniDigitalnihoUkonuContextInstance =
	ZadostOOsvedceniDIGUkonuContextInstance;
export const getVypisBodovehoHodnoceniRidiceContext =
	VypisBodovehoHodnoceniRidiceContextInstance;
export const getVypisZVerejnehoRejstrikuContextInstance =
	VypisZVerejnehoRejstrikuContextInstance;
export const getPodaniZadostiInformaceContext =
	PodaniZadostiInformaceContextInstance;
export const getPotvrzeniStudiuContext = PotvrzeniStudiuContextInstance;
export const getVypisUdajuZROBContextInstance = VypisUdajuZROBContextInstance;
export const getZadostOPoskytnutiUdajuContextInstance =
	ZadostOPoskytnutiUdajuContextInstance;
export const getTrestniOznameniContextInstance = TrestniOznameniContextInstance;

export const getVolicskyPrukazSeznamContextInstance =
	VolicskyPrukazSeznamContextInstance;
export const getVolicskyPrukazDetailContextInstance =
	VolicskyPrukazDetailContextInstance;

export * from './types/serviceTypeHelpers';
export * from './volicsky-prukaz/detail/context.types';
