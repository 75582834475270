import { FormErrorMessage } from 'libs/ui/web/src/Form/FormMessage';
import { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { get } from 'react-hook-form';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useRecipientSearchAutocomplete } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { FormAutocomplete } from '@gov-nx/ui/web';
import { throttle } from '@gov-nx/utils/common';
import { DataBoxListItem } from './DataBoxListItem';

export const RecipientAutocomplete = () => {
	const { form } = useDataBoxMessageFormContext();
	const error = get(form.formState.errors, 'recipientAutocomplete');
	const hasError = Boolean(error);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { isFetching, result, text, setText } =
		useRecipientSearchAutocomplete();

	const getDataBoxListItem = (dataBox: DatovkaApiType, searchValue: string) => {
		return renderToStaticMarkup(
			<DataBoxListItem
				dataBox={dataBox}
				searchValue={searchValue}
			/>
		);
	};

	const recipientTemplateResolver = useCallback(
		(item: DatovkaApiType) => {
			return getDataBoxListItem(item, text);
		},
		[text]
	);

	return (
		<div className={'gov-form-control ds-new-message pr-0.5'}>
			<FormAutocomplete
				onInput={throttle((value) => setText(value), 200)}
				onSelect={() => setText('')}
				label={{
					children: `${tn('nova-zprava.komu')}:`,
					required: true,
				}}
				field={{
					name: 'recipientAutocomplete',
					placeholder: tn('nova-zprava.vyhledejte'),
					options: result || [],
					processing: isFetching,
					messageEmpty: tn('nova-zprava.zadny-vysledek'),
					messageLoading: tn('nova-zprava.nacitam'),
					templateResolver: recipientTemplateResolver,
					valueResolver: () => '',
				}}
				control={{
					invalid: hasError,
				}}
			/>
			{error && (
				<div className={'gov-form-control__bottom'}>
					<FormErrorMessage
						slot="bottom"
						error={error}
					/>
				</div>
			)}
		</div>
	);
};
