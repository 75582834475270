import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { arrayEquals } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';

const isFO = (types: DatovkaApiType['typSchranky'][]) =>
	arrayEquals(types, ['FO']);

interface DataBoxAccessHookProps {
	requiredTypes: DatovkaApiType['typSchranky'][];
	translationPrefix?: string;
	possibleToConnect: boolean;
	possibleToCreate: boolean;
	listToCreateTypes: DatovkaApiType['typSchranky'][];
}

export const useDataBoxConnectBanner = ({
	requiredTypes,
	possibleToConnect,
	translationPrefix,
	listToCreateTypes,
}: DataBoxAccessHookProps) => {
	const { t } = useLocale(PageCode['datove-schranky']);

	const keyBase = translationPrefix || 'banner-service';
	const keyPrefixCreate = keyBase + '.zalozeni';
	const keyPrefixConnect = keyBase + '.pripojeni';
	const keyPrefix = possibleToConnect ? keyPrefixConnect : keyPrefixCreate;
	const keyPostfix = isFO(requiredTypes) ? '-FO' : '';

	const showSecondaryButton = listToCreateTypes.includes('FO');

	const strings = {
		primaryButton: {
			content: t(keyPrefix + '.cta'),
			wcag: t(keyPrefix + '.wcag.cta'),
		},
		secondaryButton: {
			content: t(keyPrefixCreate + '.cta-fo'),
			wcag: t(keyPrefixCreate + '.wcag.cta-fo'),
		},
		title: t(`${keyPrefix}.nadpis${keyPostfix}`),
		content: t(`${keyPrefix}.popis`),
	};

	return { showSecondaryButton, strings };
};
