import { GovIcon } from '@gov-design-system-ce/react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxLabel,
	getDataBoxIcon,
	useDataBoxLabel,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { CopyButton } from '../../Button/CopyButton';

export const DataBoxRecipientDetailContent = ({
	dataBox,
}: {
	dataBox: DatovkaApiType;
}) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { label } = useDataBoxLabel();

	return (
		<div
			className={
				'p-4 bg-neutral-white shadow rounded w-[280px] [ sm:w-[400px] ] [ ds:w-[280px] ds:p-6 ] [ lg:w-[380px] ] [ xl:w[400px] ]'
			}>
			<div className={''}>
				<h3 className={'mb-4 text-l'}>{dataBoxLabel(dataBox, true)}</h3>
				<dl>
					<div
						className={
							'flex items-center gap-1 text-secondary-700 [&:not(:last-of-type)]:mb-2'
						}>
						<dt>{tn('nova-zprava.prijemce.id-schranky')}:</dt>
						<dd>
							<strong>{dataBox.datovaSchrankaId}</strong>
						</dd>
						{dataBox.datovaSchrankaId ? (
							<CopyButton
								value={dataBox.datovaSchrankaId}
								label={tn(
									'nova-zprava.prijemce.zkopirovat-id-schranky'
								)}></CopyButton>
						) : null}
					</div>
					{dataBox.ico ? (
						<div className={'flex items-center gap-1 text-secondary-700'}>
							<dt>{tn('nova-zprava.prijemce.ico')}:</dt>
							<dd>
								<strong>{dataBox.ico}</strong>
							</dd>
							<CopyButton
								value={dataBox.ico}
								label={tn('nova-zprava.prijemce.zkopirovat-ico')}></CopyButton>
						</div>
					) : null}
				</dl>
			</div>
			<div className={'h-[1px] my-4 bg-secondary-400 [ ds:my-6 ]'}></div>
			<div className={'flex flex-col gap-4'}>
				<p className={'flex items-center gap-3'}>
					<GovIcon
						name={getDataBoxIcon(dataBox.typSchranky)}
						className="w-4 h-4 grow-0 shrink-0"
					/>
					<span className={'text-secondary-700'}>
						{dataBox.typSchranky ? label(dataBox.typSchranky) : null}
					</span>
				</p>
				<p className={'flex items-center gap-3'}>
					<GovIcon
						name="coins"
						className="w-4 h-4 grow-0 shrink-0"
					/>
					<span className={'text-secondary-700'}>
						{/* @TODO use configuration value for message price */}
						{tn('nova-zprava.prijemce.odeslani-zpravy')}{' '}
						<strong>
							{dataBox.typSchranky?.startsWith('OVM')
								? tn('nova-zprava.prijemce.zdarma')
								: '10 Kč'}
						</strong>
					</span>
				</p>
				<p className={'flex gap-3'}>
					<GovIcon
						name="geo-alt-fill"
						className="w-4 h-4 ml-0.5 mt-1 grow-0 shrink-0"
					/>
					<span className={'text-secondary-700'}>{dataBox.adresa}</span>
				</p>
			</div>
		</div>
	);
};
