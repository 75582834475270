import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';
import {
	deleteEDocumentQuery,
	eDocumentsQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { EDocumentsContext } from './context.types';

const ProfileCertificatesContext = createContext<EDocumentsContext>({
	documents: [],
	initialLoading: true,
	isDeleting: false,
	onDeleteEDocument: () => null,
});

interface ProfileEDocumentsContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export const ProfileEDocumentsContextProvider = ({
	code,
	children,
}: ProfileEDocumentsContextProviderProps) => {
	const queryClient = useQueryClient();
	const { toastMessageSuccess, toastMessageError } = useMessageEvents();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { useDummyDataForEdocuments } = useConfiguration();

	const eDocuments = usePoQuery({
		queryKey: ['edocuments'],
		queryFn: async () =>
			eDocumentsQuery(
				useDummyDataForEdocuments === 'true' ? { text: 'DUMMY' } : undefined
			),
	});

	const deleteEDocument = usePoMutation({
		mutationFn: deleteEDocumentQuery,
		onSuccess: async () => {
			toastMessageSuccess(tsn('formular.zprava.odebrano'));
			await queryClient.invalidateQueries({ queryKey: ['edocuments'] });
		},
	});

	return (
		<ProfileCertificatesContext.Provider
			value={{
				documents: eDocuments.data?.seznam ?? [],
				initialLoading: eDocuments.isLoading,
				onDeleteEDocument: (id, onSettled) =>
					deleteEDocument.mutate(id, {
						onError: () =>
							toastMessageError(tsn('formular.zprava.nepovedlo-se-odebrat')),
						onSettled,
					}),
				isDeleting: deleteEDocument.isLoading,
			}}>
			{children}
		</ProfileCertificatesContext.Provider>
	);
};

export const useProfileEDocumentsContextInstance = (): EDocumentsContext =>
	useContext(ProfileCertificatesContext);
