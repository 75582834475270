import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useState,
} from 'react';
import { CmsMessage } from '@gov-nx/api/common';
import { AppLoaderControl, Nullable, Optional } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { AppContextType } from './AppContext.types';

export const AppContext = createContext<Nullable<AppContextType>>(null);

export interface AppContextProviderProps extends PropsWithChildren {
	loaderControl?: AppLoaderControl;
}

export function AppContextProvider({
	children,
	loaderControl,
}: AppContextProviderProps) {
	const [messages, setMessages] = useState<CmsMessage[]>([]);

	const [isDashboardPage, setDashboardPage] = useState<boolean>(false);
	const [loading, setAppLoading] = useState<boolean>(true);
	const [loadingMessage, setLoadingMessage] =
		useState<Optional<string>>(undefined);
	const [appError, setAppError] = useState<Error | null>(null);

	const [currentPageCode, setCurrentPageCode] =
		useState<Nullable<PageCode>>(null);
	const [currentServiceCode, setCurrentServiceCode] =
		useState<Nullable<ServiceCode>>(null);

	const setCurrentCode = (
		pageCode: Nullable<PageCode>,
		serviceCode: Nullable<ServiceCode>
	) => {
		if (pageCode) {
			setCurrentPageCode(pageCode);
			setCurrentServiceCode(null);
		} else if (serviceCode) {
			setCurrentPageCode(null);
			setCurrentServiceCode(serviceCode);
		}
	};

	const setLoading = (status: boolean, message?: string) => {
		setAppLoading(status);
		setLoadingMessage(message);

		if (loaderControl?.show() && status) {
			loaderControl.show(message);
		}
		if (loaderControl?.hide() && !status) {
			loaderControl?.hide();
		}
	};

	return (
		<AppContext.Provider
			value={{
				loading,
				setLoading,
				loadingMessage,
				setLoadingMessage,
				appError,
				setAppError,
				isDashboardPage,
				setDashboardPage,
				currentPageCode,
				currentServiceCode,
				setCurrentCode,
				messages,
				setMessages,
			}}>
			{children}
		</AppContext.Provider>
	);
}

export const useAppContext = (): AppContextType =>
	useContext(AppContext) as AppContextType;
