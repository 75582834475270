import {
	CiselnikyVolbyDto,
	MySubmissionVoterCardAplication,
	PodaniChybaDto,
	PodaniChybyDto,
} from '@gov-nx/api/portal-obcana';
import {
	getEntries,
	hasProperties,
	is,
	Optional,
	propEq,
} from '@gov-nx/core/types';
import { CheckboxProps } from '@gov-nx/ui/types';
import { toDateRangeReadable, toDateReadable } from '@gov-nx/utils/common';
import { VotersCardFormData } from './context.types';
import { convertToRoman } from './formDefinitions';

const first = <T extends Array<unknown>>(array: T): T[0] => array[0];

export const getSelectedRoundKeys = (
	electionRound: VotersCardFormData['electionRound']
) => {
	return getEntries(electionRound ?? {})
		.filter(([_, isChecked]) => isChecked)
		.map(first);
};

export const getSelectedRounds = (
	formData: VotersCardFormData['electionRound'],
	fields: CheckboxProps[]
): string => {
	const checkedKeys = getSelectedRoundKeys(formData);

	const rounds = fields
		.filter((field) => {
			const [_, round] = field.name.split('.');
			return checkedKeys.includes(round);
		})
		.map((field) => field.label?.children)
		.join(', ');

	return rounds;
};

export const canVoteFromAbroad = (druh: CiselnikyVolbyDto['druh']): boolean => {
	const ok = ['POSLANECKA_SNEMOVNA', 'PREZIDENT'];
	// const nook = ['SENAT', 'KRAJ', 'EVROPSKY_PARLAMENT'];
	return druh ? ok.includes(druh) : false;
};

export const isTwoRoundedElection = (
	druh: CiselnikyVolbyDto['druh']
): boolean => {
	const dvoukolove = ['SENAT', 'PREZIDENT'];
	return druh ? dvoukolove.includes(druh) : false;
};

export const isBothRoundsRequested = (
	params: MySubmissionVoterCardAplication['parametry']
) => {
	const requestedFirstRound =
		params.kolo1zadost && [true, 'true'].includes(params.kolo1zadost);
	const requestedSecondRound =
		params.kolo2zadost && [true, 'true'].includes(params.kolo2zadost);

	return isTwoRoundedElection(params.druh)
		? requestedFirstRound && requestedSecondRound
		: true;
};

export interface PodaniChybyDtoParams {
	kraj?: string; // "Středočeský kraj",
	volebniObvod?: string; // null,
	volebniObvodKod?: string; // "6",
	vyzvednutiNaUradu?: string; //": "Městská část Brno-střed, Dominikánská 264/2, Brno-město, 60200 Brno",
	uradNazev?: string; // "Městská část Brno-střed",
	uradAdresa?: string; // "Dominikánská 264/2, Brno-město, 60200 Brno",
	vyzvednutiNaUraduDatumOd?: string; // "2024-03-10",
	vyzvednutiNaUraduDatumDo?: string; //"2024-03-24",
	uradOdesleNejdrive?: string; // "2024-03-10"
	rokSenatnichVolebVeVasemObvodu?: string;
	datumDoOsobne?: string;
	datumDoPisemne?: string;
	urad?: string;
	rokKomunalnichVoleb?: string;
	seznamKraju?: string;
	datumDoElektronicky?: string;
}

export interface IElectionControl {
	params?: PodaniChybyDtoParams;
	errors: { code: string; message: string }[];
}

const getMessage = (
	error: PodaniChybaDto,
	tsn: (key: string, params?: Record<string, string | number>) => string
): Optional<string> => {
	const simpleMessages = [
		'NesplnenaPodminkaJednaZadost',
		'PouzeObcanCR',
		'PouzeObcanEU',
		'NesplnenaPodminkaVek',
		'ChybaZjisteniUradu',
		'ROBNemaAdresuPobytu',
		'NesplnenaPodminkaPripojeniDSFO',
	];
	const fallbackParam = tsn('volby.error.neuvedeno');

	const params = error.parametry as Optional<PodaniChybyDtoParams>;
	if (error.extKod && simpleMessages.includes(error.extKod)) {
		return tsn(`volby.error.${error.extKod}`);
	}
	if (error.extKod === 'NesplnenaPodminkaSenatniObvod') {
		return tsn('volby.error.NesplnenaPodminkaSenatniObvod', {
			rok: params?.rokSenatnichVolebVeVasemObvodu ?? fallbackParam,
		});
	}
	if (error.extKod === 'NesplnenaPodminkaKrajPraha') {
		return tsn('volby.error.NesplnenaPodminkaKrajPraha', {
			rok: params?.rokKomunalnichVoleb ?? fallbackParam,
		});
	}
	if (error.extKod === 'NesplnenaPodminkaKraj') {
		return tsn('volby.error.NesplnenaPodminkaKraj', {
			seznamKraju: params?.seznamKraju ?? fallbackParam,
		});
	}
	if (error.extKod === 'PodatNaUradeDatumNarozeni') {
		return tsn('volby.error.PodatNaUradeDatumNarozeni', {
			datumDoPisemne: params?.datumDoPisemne ?? fallbackParam,
			datumDoOsobne: params?.datumDoOsobne ?? fallbackParam,
			urad: params?.urad ?? fallbackParam,
		});
	}
	if (error.extKod === 'NesplnenaPodminkaTerminPodani') {
		return tsn('volby.error.NesplnenaPodminkaTerminPodani', {
			datumDoElektronicky: params?.datumDoElektronicky ?? fallbackParam,
			urad: params?.urad ?? fallbackParam,
			datumDoOsobne: params?.datumDoOsobne ?? fallbackParam,
		});
	}
	return error.extPopis;
};

export const transformControl = (
	data: PodaniChybyDto,
	tsn: (key: string, params?: Record<string, string | number>) => string
): IElectionControl => {
	// hodi se pro lokalni testovani
	// const data: PodaniChybyDto = {
	// 	chyby: [
	// 		{
	// 			extKod: 'InfoKontrola',
	// 			extPopis: 'InfoKontrola.',
	// 			uroven: 'INFO',
	// 			parametry: {
	// 				kraj: 'Středočeský kraj',
	// 				volebniObvod: null,
	// 				volebniObvodKod: '6',
	// 				vyzvednutiNaUradu:
	// 					'Městská část Brno-střed, Dominikánská 264/2, Brno-město, 60200 Brno',
	// 				uradNazev: 'Městská část Brno-střed',
	// 				uradAdresa: 'Dominikánská 264/2, Brno-město, 60200 Brno',
	// 				vyzvednutiNaUraduDatumOd: '2024-03-10',
	// 				vyzvednutiNaUraduDatumDo: '2024-03-24',
	// 				uradOdesleNejdrive: '2024-03-10',
	// 			},
	// 			kod: 'INFO',
	// 			popis: 'Informace',
	// 		},
	// 	],
	// };

	if (!data || !data.chyby) {
		return { errors: [] };
	}

	const errors = data.chyby
		.filter(propEq('uroven', 'CHYBA'))
		.map<{ message?: string; code?: string }>((item) => ({
			message: getMessage(item, tsn),
			code: item.extKod,
		}))
		.filter(hasProperties(['message', 'code']));

	return {
		errors,
		params: getParams(data),
	};
};

const getParams = (data: PodaniChybyDto): Optional<PodaniChybyDtoParams> => {
	const params = data?.chyby?.map((item) => {
		return item.parametry as PodaniChybyDtoParams;
	});

	return params ? params[0] : {};
};

const filledDays = (
	array: [Optional<string>, Optional<string>, boolean]
): array is [string, string, boolean] => !!array[0] && !!array[1];

export const transformElectionRounds = (
	submission: MySubmissionVoterCardAplication
) => {
	const {
		kolo1datumOd,
		kolo1datumDo,
		kolo1zadost,
		kolo2datumOd,
		kolo2datumDo,
		kolo2zadost,
	} = submission.parametry;

	const data: Array<[Optional<string>, Optional<string>, boolean]> = [
		[kolo1datumOd, kolo1datumDo, kolo1zadost === 'true'],
		[kolo2datumOd, kolo2datumDo, kolo2zadost === 'true'],
	];

	return data.filter(filledDays).map(([from, to, isRequested], index) => ({
		from: toDateReadable(from),
		to: toDateReadable(to),
		round: convertToRoman(index + 1),
		isRequested,
	}));
};

export const getElectionDates = (
	election?: CiselnikyVolbyDto
): Optional<string> => {
	return election?.kola
		?.filter(hasProperties(['terminOd', 'terminDo']))
		.map((round) => toDateRangeReadable(round.terminOd, round.terminDo))
		.join(', ');
};

export const getElectionSubmissionDates = (
	submission: MySubmissionVoterCardAplication
): Optional<string> => {
	return [
		toDateRangeReadable(
			submission?.parametry.kolo1datumOd,
			submission?.parametry.kolo1datumDo
		),
		submission?.parametry.kolo2datumOd && submission?.parametry.kolo2datumDo
			? toDateRangeReadable(
					submission?.parametry.kolo2datumOd,
					submission?.parametry.kolo2datumDo
			  )
			: undefined,
	]
		.filter(is)
		.join(', ');
};

export const pickupDates = (
	submission: MySubmissionVoterCardAplication
): string => {
	const { vyzvednutiNaUraduDatumOd, vyzvednutiNaUraduDatumDo } =
		submission.parametry;

	return [
		vyzvednutiNaUraduDatumOd
			? toDateReadable(vyzvednutiNaUraduDatumOd)
			: '&nbsp;',
		vyzvednutiNaUraduDatumDo
			? toDateReadable(vyzvednutiNaUraduDatumDo)
			: '&nbsp;',
	].join(' - ');
};

export const getDetailPageName = (
	translate: (key: string) => string,
	election?: CiselnikyVolbyDto
): string => {
	const nadpis = translate('volby.nadpis');
	const druh = translate(`volby.druh.${election?.druh}`);
	const kola = election?.kola
		?.map((round) => {
			if (round.terminOd && round.terminDo) {
				const date = `${toDateReadable(round.terminOd)} - ${toDateReadable(
					round.terminDo
				)}`;
				if (round.kolo === 1) return date + ' a ';
				else return date;
			}
		})
		.join('');

	return nadpis + ': ' + druh + ' ' + `(${kola})`;
};
