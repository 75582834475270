import {
	GovChip,
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	DataBoxTimeDivisionType,
	getDaysToAutomaticRemoval,
	getMessageParams,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Checkbox } from '@gov-nx/ui/web';
import { HighlightTextMemo, isUnreadMessage } from '@gov-nx/utils/common';
import { PoMarkdownRenderWeb } from '../../Markdown/PoMarkdownRenderWeb';

interface DataBoxMessageItemProps {
	message: DatoveSchrankyDatovaZpravaDto & {
		dnuDoOdstraneni?: number;
	};
	timePeriodLabel: DataBoxTimeDivisionType;
}

export const DataBoxMessageItem = ({
	message,
	timePeriodLabel,
}: DataBoxMessageItemProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const {
		messagesFilter: { dataBoxId, folderType, query, messageId },
		messagesSelect: {
			showCheckboxes,
			updateSelectedMessageList,
			selectedMessageList,
		},
		dataBoxDateUtils: { getMessageDate },
	} = useDataBoxMessageListContext();

	const { showRemovalInfo, daysToAutomaticRemoval } =
		getDaysToAutomaticRemoval(message);

	const actionWrapper = ({ children }: PropsWithChildren) => {
		if (showCheckboxes) {
			return <label className={'flex-1 py-5 no-underline'}>{children}</label>;
		} else {
			return (
				<Link
					to={routeResolver(PageCode['datove-schranky-zprava'], {
						dataBoxId,
						folderType,
						messageId: message.datovaZpravaId,
					})}
					className={'flex-1 py-5 no-underline'}>
					{children}
				</Link>
			);
		}
	};

	const { unreadMessage, person, highlightWholePerson, highlightWholeSubject } =
		getMessageParams({
			message,
			dataBoxId,
			folderType,
			query,
		});

	return (
		<li
			className={cx(
				'border-b border-secondary-300 [ last-of-type:border-0 ]',
				messageId === message.datovaZpravaId
					? 'bg-primary-200'
					: 'anim-bg-hover [ hover:bg-primary-100 ]'
			)}>
			{message.datovaZpravaId && (
				<article className={'relative flex'}>
					{actionWrapper({
						children: (
							<div
								className={cx(
									'flex w-full pl-3 pr-4 border-l-4',
									messageId === message.datovaZpravaId
										? 'border-primary-600'
										: 'border-transparent'
								)}>
								{showCheckboxes && (
									<div className={'ds-checkbox-item w-6 mr-2 grow-0 shrink-0'}>
										{folderType === DataBoxFolderType.Received &&
										message.prijemceId === dataBoxId &&
										isUnreadMessage(message) ? (
											<GovTooltip
												slot="left-icon"
												icon>
												<GovIcon
													name="exclamation-triangle-fill"
													className={'text-warning-400'}
												/>
												<GovTooltipContent>
													{tn('zprava.varovani.nutna-akce')}
												</GovTooltipContent>
											</GovTooltip>
										) : (
											<Checkbox
												name={'message' + message.datovaZpravaId}
												checked={selectedMessageList.includes(
													message.datovaZpravaId
												)}
												onChange={() => {
													updateSelectedMessageList(
														message.datovaZpravaId as number
													);
												}}
												noLabel={true}
												size="s"
											/>
										)}
									</div>
								)}
								<div className={'flex flex-col gap-2 w-full'}>
									<div className={'flex justify-between items-center gap-4'}>
										<h3
											className={cx(
												'text-primary-600',
												unreadMessage && 'font-bold'
											)}>
											{query ? (
												<HighlightTextMemo
													text={person}
													search={highlightWholePerson ? person : query}
												/>
											) : (
												person
											)}
										</h3>
										<time
											className={cx(
												'text-xs text-secondary-700',
												unreadMessage && 'font-bold'
											)}>
											{folderType === DataBoxFolderType.Trash
												? !!message.datumSmazani &&
												  getMessageDate(message.datumSmazani, timePeriodLabel)
												: !!message.datumACasDodani &&
												  getMessageDate(
														message.datumACasDodani,
														timePeriodLabel
												  )}
										</time>
									</div>
									<div className={'flex justify-between items-start gap-2'}>
										<p
											className={cx(
												'mr-auto text-s text-secondary-700',
												unreadMessage && 'font-bold'
											)}>
											{query ? (
												<HighlightTextMemo
													text={message.vec as string}
													search={
														highlightWholeSubject
															? (message.vec as string)
															: query
													}
												/>
											) : (
												message.vec
											)}
										</p>
										{message.datumArchivace &&
											folderType !== DataBoxFolderType.Archived && (
												<GovTooltip
													position="left"
													icon>
													<GovChip
														variant="success"
														size="xs"
														inverse>
														<GovIcon
															slot="left-icon"
															name="archived-outline"
														/>
													</GovChip>
													<GovTooltipContent>
														{tn('zpravy.archivovano')}
													</GovTooltipContent>
												</GovTooltip>
											)}
										{[
											DataBoxFolderType.Archived,
											DataBoxFolderType.Trash,
										].includes(folderType) && (
											<span
												className={
													'flex gap-2 mt-0.5 text-xs text-secondary-700'
												}>
												{dataBoxId === message.prijemceId ? (
													<>
														{tn('slozky.dorucene')}
														<GovIcon
															name="envelope"
															className={'inline-flex w-3 h-4'}
														/>
													</>
												) : (
													<>
														{tn('slozky.odeslane')}
														<GovIcon
															name="send"
															className={'inline-flex w-3 h-4'}
														/>
													</>
												)}
											</span>
										)}
										{unreadMessage && (
											<GovTooltip
												position="left"
												icon>
												<span className="flex grow-0 shrink-0 w-4 h-4 mt-0.5 rounded-full bg-warning-400"></span>
												<GovTooltipContent>
													{tn('zpravy.neprecteno')}
												</GovTooltipContent>
											</GovTooltip>
										)}
									</div>

									{showRemovalInfo &&
										[
											DataBoxFolderType.Received,
											DataBoxFolderType.Sent,
										].includes(folderType) && (
											<div className={'flex gap-2 items-center'}>
												{message.datumArchivace ? (
													<>
														<GovIcon
															name="info-circle"
															className={'inline-flex w-3 text-secondary-700'}
														/>
														<PoMarkdownRenderWeb
															applyClasses={{
																paragraph: 'text-xs text-secondary-700',
															}}>
															{tn('zpravy.po-odstraneni-archiv', {
																count: daysToAutomaticRemoval,
															})}
														</PoMarkdownRenderWeb>
													</>
												) : (
													<>
														<GovIcon
															name="trash"
															className={'inline-flex w-3 text-secondary-700'}
														/>
														<PoMarkdownRenderWeb
															applyClasses={{
																paragraph: 'text-xs text-secondary-700',
															}}>
															{tn('zpravy.automaticke-trvale-odstraneni-za', {
																count: daysToAutomaticRemoval,
															})}
														</PoMarkdownRenderWeb>
													</>
												)}
											</div>
										)}
									{!!message.dnuDoOdstraneni &&
										[DataBoxFolderType.Trash].includes(folderType) && (
											<div className={'flex gap-2 items-center'}>
												<GovIcon
													name="trash"
													className={'inline-flex w-3 text-secondary-700'}
												/>
												<PoMarkdownRenderWeb
													applyClasses={{
														paragraph: 'text-xs text-secondary-700',
													}}>
													{tn('zpravy.automaticke-trvale-odstraneni-kopie-za', {
														count: message.dnuDoOdstraneni,
													})}
												</PoMarkdownRenderWeb>
											</div>
										)}
								</div>
							</div>
						),
					})}
				</article>
			)}
		</li>
	);
};
