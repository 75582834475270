import { GovIcon } from '@gov-design-system-ce/react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxLabel,
	getDataBoxIcon,
	useDataBoxLabel,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { HighlightTextMemo } from '@gov-nx/utils/common';

export const DataBoxListItem = ({
	dataBox,
	searchValue,
}: {
	dataBox: DatovkaApiType;
	searchValue: string;
}) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { label } = useDataBoxLabel();

	return (
		<li className="border-b border-secondary-400 -mb-3 pb-3">
			<div className={'flex flex-col mb-3 [ md:flex-row ]'}>
				<strong className={'md:pr-3'}>
					<HighlightTextMemo
						text={dataBoxLabel(dataBox, true)}
						search={searchValue}
					/>
				</strong>
				<span
					className={
						'text-s text-secondary-700 [ md:pl-3 md:border-l md:border-secondary-400 ]'
					}>
					<HighlightTextMemo
						text={dataBox.datovaSchrankaId || ''}
						search={searchValue}
					/>
				</span>
			</div>
			<div className={'flex gap-4 mb-2 text-secondary-700'}>
				<span className={'flex items-center text-s'}>
					<span className="inline-flex w-4 h-4 mr-2 text-secondary-800 grow-0 shrink-0">
						<GovIcon
							name={getDataBoxIcon(dataBox.typSchranky)}
							className="w-4 h-4"
						/>
					</span>
					{dataBox.typSchranky ? label(dataBox.typSchranky) : null}
				</span>
				<span className={'flex items-center text-s'}>
					<span className="inline-flex w-4 h-4 mr-2 text-secondary-800 grow-0 shrink-0">
						<GovIcon
							name="coins"
							className="w-4 h-4"
						/>
					</span>
					<span className={'text-secondary-700'}>
						{/* @TODO use configuration value for message price */}
						{tn('nova-zprava.prijemce.odeslani-zpravy')}{' '}
						{dataBox.typSchranky?.startsWith('OVM') ? (
							tn('nova-zprava.prijemce.zdarma')
						) : (
							<span className="whitespace-nowrap">10 Kč</span>
						)}
					</span>
				</span>
			</div>
			<div className={'flex items-center text-s text-secondary-700'}>
				<span className="inline-flex w-4 h-4 mr-2 text-secondary-800 grow-0 shrink-0">
					<GovIcon
						name="geo-alt-fill"
						className="w-4 h-4"
					/>
				</span>
				<HighlightTextMemo
					text={dataBox.adresa || ''}
					search={searchValue}
				/>
			</div>
		</li>
	);
};
