import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	ContactDataSection,
	EmailFormData,
	EmailFormInstance,
	UpdateContactHook,
	useContactDetails,
} from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { IUseBoolean } from '@gov-nx/utils/common';

interface EmailContactDetailsHookProps {
	updateContact: UpdateContactHook<EmailFormData>;
	isReloading: boolean;
	refetch: () => Promise<void>;
	unverifiedEmail?: string;
	emailVerified?: string;
	isEditing: IUseBoolean;
}

export const useEmailContactDetails = ({
	refetch,
	isReloading,
	updateContact,
	unverifiedEmail,
	emailVerified,
	isEditing,
}: EmailContactDetailsHookProps): ContactDataSection<EmailFormData> => {
	const code = PageCode['nastaveni'];
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(code);
	const queryClient = useQueryClient();

	const { toastMessageSuccess, toastMessageError } = useMessageEvents();

	const {
		isChanging,
		isDeleteConfirmationOpen,
		isNeedForVerification,
		cleanNotificationSettingsCache,
	} = useContactDetails({ unverifiedValue: unverifiedEmail });

	const formDefinition = EmailFormInstance({
		isNeedForVerification,
	});

	useEffect(() => {
		if (isChanging.value) {
			formDefinition.formMethods.setValue('email', '');
		}

		if (!isChanging.value && unverifiedEmail) {
			formDefinition.formReset();
			formDefinition.formMethods.setValue('email', unverifiedEmail);
		}
	}, [unverifiedEmail, formDefinition.formMethods, isChanging.value]);

	const onDelete = () =>
		updateContact.delete(undefined, {
			onSuccess: async () => {
				await refetch();
				isDeleteConfirmationOpen.setFalse();
				await cleanNotificationSettingsCache();
				toastMessageSuccess(t('kontakt.akce.smazat.potvrzeni.smazano'));
			},
		});

	const onResendVerification = () =>
		updateContact.resendVerification(undefined, {
			onSuccess: () => {
				toastMessageSuccess(t(`messages.overeni-email-odeslano`));
			},
			onError: () => {
				toastMessageError(t('messages.overeni-email-neodeslano'));
			},
		});

	const onUpdate = () =>
		updateContact.update(formDefinition.formMethods.getValues(), {
			onSuccess: async () => {
				isEditing.setFalse();
				isChanging.setFalse();
				await refetch();
				formDefinition.formReset();
				await queryClient.invalidateQueries({
					queryKey: ['setting-notifications', 'NU_EMAIL'],
				});
			},
			onError: async () => {
				toastMessageError(t(`messages.nepovedlo-se`));
			},
		});

	const onPersonalContactsUse = (email: string) =>
		updateContact.update(
			{ email },
			{
				onSuccess: async () => {
					await refetch();
					await queryClient.invalidateQueries({
						queryKey: ['setting-notifications', 'NU_EMAIL'],
					});
				},
			}
		);

	const onVerify = () =>
		updateContact.verify(formDefinition.formMethods.getValues(), {
			onSuccess: async () => {
				isEditing.setFalse();
				await refetch();
				formDefinition.formReset();
				await queryClient.invalidateQueries({
					queryKey: ['setting-notifications', 'NU_EMAIL'],
				});
				toastMessageSuccess(t(`messages.overeni.email.uspesne`));
			},
			onError: async () => {
				toastMessageError(t(`messages.overeni.email.spatny-kod`));
				formDefinition.resetField('code');
			},
		});

	const onSubmit = formDefinition.formMethods.handleSubmit(
		isNeedForVerification ? onVerify : onUpdate
	);

	return {
		isReloading,
		isDeleteConfirmationOpen,
		isChanging,
		isEditing,
		onSubmit,
		onPersonalContactsUse,
		formDefinition,
		isNeedForVerification,
		onDelete,
		isDeleting: updateContact.isDeleting,
		isUpdating: updateContact.isUpdating || updateContact.isVerifying,
		isVerificationResending: updateContact.isVerificationResending,
		onChange: isChanging.setTrue,
		valueVerified: emailVerified,
		onResendVerification: unverifiedEmail ? onResendVerification : undefined,
	};
};
