import {
	differenceInCalendarDays,
	isToday,
	subYears,
	isAfter,
	endOfYear,
} from 'date-fns';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useAppLanguage } from '@gov-nx/core/hooks';
import {
	toDateReadable,
	toDateShortReadable,
	toTimeReadable,
	toWeekDay,
	today,
	isUnreadMessage,
} from '@gov-nx/utils/common';
import {
	DataBoxFolderType,
	DataBoxTimeDivisionType,
} from '../providers/DataBoxes.types';

const AUTOMATIC_REMOVAL_AFTER_DAYS = 90;
const DAYS_TO_INFORM_BEFORE_REMOVAL = 30;

export const daysSinceDeliveryDate = (message: DatoveSchrankyDatovaZpravaDto) =>
	differenceInCalendarDays(today(), today(message.datumACasDoruceni as string));

export const getDaysToAutomaticRemoval = (
	message: DatoveSchrankyDatovaZpravaDto
) => {
	const daysSinceDelivery = daysSinceDeliveryDate(message);
	const showRemovalInfo =
		daysSinceDelivery >=
		AUTOMATIC_REMOVAL_AFTER_DAYS - DAYS_TO_INFORM_BEFORE_REMOVAL;
	const daysToAutomaticRemoval =
		AUTOMATIC_REMOVAL_AFTER_DAYS - daysSinceDelivery;

	return { showRemovalInfo, daysToAutomaticRemoval };
};

export const useDataboxDateUtils = () => {
	const { language } = useAppLanguage();

	const getMessageDate = (
		date: string,
		timePeriod: DataBoxTimeDivisionType
	) => {
		if (timePeriod === DataBoxTimeDivisionType.Today) {
			return toTimeReadable(today(date));
		} else if (differenceInCalendarDays(today(), today(date)) < 7) {
			return toWeekDay(today(date), language);
		} else if (
			[
				DataBoxTimeDivisionType.LastYear,
				DataBoxTimeDivisionType.Older,
			].includes(timePeriod)
		) {
			return toDateReadable(today(date));
		} else {
			return toDateShortReadable(today(date));
		}
	};

	const getMessageDateLabel = (date: string) => {
		if (isToday(today(date))) {
			return toTimeReadable(today(date));
		} else if (differenceInCalendarDays(today(), today(date)) < 7) {
			return toWeekDay(today(date), language);
		} else if (isAfter(today(date), subYears(endOfYear(today()), 1))) {
			return toDateShortReadable(today(date));
		} else {
			return toDateReadable(today(date));
		}
	};

	return { getMessageDate, getMessageDateLabel };
};

export const getMessageParams = ({
	message,
	dataBoxId,
	folderType,
	query,
}: {
	message: DatoveSchrankyDatovaZpravaDto;
	dataBoxId: string;
	folderType: DataBoxFolderType;
	query?: string;
}) => {
	const unreadMessage =
		folderType === DataBoxFolderType.Received &&
		message.prijemceId === dataBoxId &&
		isUnreadMessage(message);

	const isReceivedMessage = dataBoxId === message.prijemceId;

	const person = (
		isReceivedMessage ? message.odesilatelNazev : message.prijemceNazev
	) as string;

	const normalizedQuery = query?.toLowerCase();

	const queryIsNotPersonNorSubject =
		!!normalizedQuery &&
		!(message.vec as string).toLowerCase().includes(normalizedQuery) &&
		!person.toLowerCase().includes(normalizedQuery);

	const highlightWholePerson =
		queryIsNotPersonNorSubject &&
		(
			(isReceivedMessage ? message.odesilatelId : message.prijemceId) as string
		).includes(normalizedQuery);

	const highlightWholeSubject =
		queryIsNotPersonNorSubject &&
		(message.datovaZpravaId as number).toString().includes(normalizedQuery);

	return {
		unreadMessage,
		person,
		highlightWholePerson,
		highlightWholeSubject,
	};
};

export const formatIdentification = (
	identification: DatoveSchrankyDatovaZpravaDto['odesilatelJmenoUzivatele']
) => identification?.split(' ', 1).join();
