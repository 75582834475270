import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { StorageUsage } from './StorageUsage';

export const DataBoxStorageUsage = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const { t } = useTranslation([PageCode['datove-schranky']]);
	const { storage } = useDataBoxesContext();
	const { dataBoxes } = useDataBoxesContext();

	if (!storage.storageUsage) return null;
	if (storage.storageUsage.hasEnoughFreeSpace) return null;

	return (
		<div className={'hidden mb-4 [ xl:block ]'}>
			<h3 className={'font-bold text-secondary-700 mb-4'}>
				{tn('uloziste.uloziste')}
			</h3>
			<div className={'rounded bg-secondary-500 mb-2'}>
				<div
					className={'rounded-l bg-error-400 h-4'}
					style={{
						width: `${
							storage.storageUsage.isStorageFull
								? 100
								: storage.storageUsage.usedInPercentInt
						}%`,
					}}
				/>
			</div>
			<dl className={'flex justify-between mb-4 text-xs text-secondary-700'}>
				<StorageUsage storage={storage.storageUsage} />
			</dl>
			<div className={'flex items-start gap-2 rounded bg-error-100 p-2'}>
				<GovIcon
					name="exclamation-triangle-fill"
					className={'grow-0 flex-shrink-0 w-3 text-error-400'}
				/>
				<p className={'text-xs'}>
					<Trans
						t={t}
						i18nKey="uloziste.procisteni"
						components={{
							'odkaz-archiv': (
								<Link
									to={
										dataBoxes[0]?.datovaSchrankaId
											? routeResolver(PageCode['datove-schranky-seznam'], {
													dataBoxId: dataBoxes[0]?.datovaSchrankaId,
													folderType: DataBoxFolderType.Archived,
											  })
											: routeResolver(PageCode['datove-schranky'])
									}
									className={'gov-link text-secondary-800'}
								/>
							),
							'odkaz-dokumenty': (
								<Link
									to={routeResolver(PageCode['moje-soubory'])}
									className={'gov-link text-secondary-800'}
								/>
							),
						}}
					/>
				</p>
			</div>
		</div>
	);
};
