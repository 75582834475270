import { WebRadioOption } from 'libs/ui/types/src/forms/FormRadio';
import { FileUploadProps } from '@gov-nx/core/hooks';

export const PodporaFileUploadParams: FileUploadProps = {
	extensions: [
		'.xml',
		'.pdf',
		'.doc',
		'.docx',
		'.odt',
		'.jpg',
		'.jpeg',
		'.png',
	],
	maxFileSize: 15 * 1024 * 1024,
	maxSumFileSize: 15 * 1024 * 1024,
	maxAttachments: 10,
	multiple: true,
};

export const zpusobPrihlaseniOptions: WebRadioOption[] = [
	{
		value: 'ID',
		label: { children: 'formular.pole.e-obcanka' },
	},
	{
		value: 'JMENO_HESLO',
		label: { children: 'formular.pole.jmeno-heslo-sms' },
	},
	{
		value: 'DS',
		label: { children: 'formular.pole.datova-schranka' },
	},
	{
		value: 'NEREGISTROVANY',
		label: { children: 'formular.pole.neregistrovany-uzivatel' },
	},
	{
		value: 'STARCOS',
		label: { children: 'formular.pole.karta-starcos' },
	},
	{
		value: 'MOBILNI_KLIC',
		label: { children: 'formular.pole.mobilni-klic' },
	},
	{
		value: 'MOJE_ID',
		label: { children: 'formular.pole.mojeID' },
	},
	{
		value: 'CSOB_ID',
		label: { children: 'formular.pole.csob-identita' },
	},
	{
		value: 'CESKA_SPORITELNA',
		label: { children: 'formular.pole.ceska-sporitelna' },
	},
	{
		value: 'KOMERCNI_BANKA',
		label: { children: 'formular.pole.komercni-banka' },
	},
	{
		value: 'Air_Bank',
		label: { children: 'formular.pole.air-bank' },
	},
	{
		value: 'MONETA_Money_Bank',
		label: { children: 'formular.pole.moneta-money-bank' },
	},
	{
		value: 'Raiffeisenbank',
		label: { children: 'formular.pole.raiffeisenbank' },
	},
	{
		value: 'FIO_banka',
		label: { children: 'formular.pole.fio-banka' },
	},
	{
		value: 'UniCredit_Bank',
		label: { children: 'formular.pole.unicredit-bank' },
	},
	{
		value: 'mBank',
		label: { children: 'formular.pole.mbank' },
	},
	{
		value: 'CREDITAS',
		label: { children: 'formular.pole.creditas-bank' },
	},
	{
		value: 'IIG',
		label: { children: 'formular.pole.iig' },
	},
];
