import { AxiosResponse } from 'axios';
import { Nullable } from '@gov-nx/core/types';
import { axiosInstance } from '../axios';
import { OsobyKomunikacniUdajeHistorieSeznamDto } from '../generated';
import {
	FirebaseTokenPayload,
	NastaveniSeznamApiActualDto,
	UserSetting,
} from '../types/userSettings';

export const saveSettingsQuery = <Value>(payload: {
	klic: string;
	hodnota: Value;
}): Promise<AxiosResponse<void, void>> => {
	return axiosInstance.post(`/api/v1/be/nastaveni`, payload, {
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

export const fetchSettingsQuery = async <Value>(
	key: string
): Promise<{
	klic: string;
	hodnota?: Value;
}> => {
	const { data } = await axiosInstance.get(`/api/v1/be/nastaveni/${key}`);
	return data;
};

export const fetchSettingsHistoryQuery =
	async (): Promise<OsobyKomunikacniUdajeHistorieSeznamDto> => {
		const { data } = await axiosInstance.get(
			`/api/v1/komunikacniudaje/historie`
		);
		return data;
	};

export const fetchUserSettingsQuery =
	async (): Promise<NastaveniSeznamApiActualDto> => {
		const { data } = await axiosInstance.get<NastaveniSeznamApiActualDto>(
			`/api/v1/nastaveni`
		);
		return data;
	};

// As of writing this, the API does not support saving more than one setting at a time.
export const saveUserSettingQuery = (
	setting: UserSetting
): Promise<AxiosResponse<void, void>> =>
	axiosInstance.post(
		`/api/v1/nastaveni`,
		{
			klic: setting.key,
			hodnota: setting.value,
		},
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);

export const saveFirebaseTokenQuery = (
	payload: FirebaseTokenPayload
): Promise<AxiosResponse<void, void>> => {
	return axiosInstance.post(
		`/api/v1/be/nastaveni/fbtoken`,
		{
			klic: payload.key,
			hodnota: payload.value,
		},
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
};

export const fetchFirebaseTokenQuery = async (
	token: string
): Promise<Nullable<string>> => {
	const key = composeKeyFromFirebaseToken(token);
	if (key === null) return null;

	try {
		const { data } = await axiosInstance.get<NastaveniSeznamApiActualDto>(
			`/api/v1/be/nastaveni/fbtoken/${key}`
		);
		if (data.seznam.length < 1) return null;
		return (data.seznam[0]?.hodnota ?? null) as Nullable<string>;
	} catch (e) {
		console.error('e', e);
	}

	return null;
};

export const deleteFirebaseTokenQuery = (
	key: string
): Promise<AxiosResponse<void, void>> => {
	return axiosInstance.delete(`/api/v1/be/nastaveni/fbtoken/${key}`);
};

export const composeKeyFromFirebaseToken = (
	token: string
): Nullable<string> => {
	if ((token?.indexOf(':') ?? -1) === -1) {
		return null;
	}
	return `N_FBTOKEN_${token?.split(':')[0]}`;
};
