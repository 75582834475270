import { CmsMessage } from '@gov-nx/api/common';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';

export const filterCmsMessagesByCodes = (
	messages: CmsMessage[],
	pageCode?: PageCode | null,
	serviceCode?: ServiceCode | null
): CmsMessage[] => {
	return messages.filter((message) => {
		const pages = Array.isArray(message.pageCodes) ? message.pageCodes : [];
		const services = Array.isArray(message.serviceCodes)
			? message.serviceCodes
			: [];

		if (pages.length || services.length) {
			if (pages.length && pageCode) {
				return message.pageCodes.includes(pageCode);
			} else if (services.length && serviceCode) {
				return message.serviceCodes.includes(serviceCode);
			} else {
				return false;
			}
		} else {
			return true;
		}
	});
};
