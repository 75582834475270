import { useQueryClient } from '@tanstack/react-query';
import {
	archiveDataBoxesMessageQuery,
	DatoveSchrankyDatovaZpravaDto,
	DatovkaNastaveniArchivaceValue,
	deleteDataBoxesMessageQuery,
	restoreDataBoxesMessageQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useArchivingSettingsQuery } from '@gov-nx/api/portal-obcana';
import {
	useDataBoxEvents,
	useDownloadEvents,
	useMessageEvents,
} from '@gov-nx/core/events';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { dateDiffInDays } from '@gov-nx/utils/common';
import {
	DataBoxConfirmModalType,
	DataBoxFolderType,
} from '../providers/DataBoxes.types';
import { DataBoxMessageDetailLoadingItemType } from '../providers/MessageDetailContext.types';
import { useSaveAllAttachmentsToDocuments } from './useSaveAllAttachmentsToDocuments';

const isReceivedBefore90Days = (message: DatoveSchrankyDatovaZpravaDto) => {
	const dateString = message.datumACasDoruceni || message.datumACasDodani;
	if (!dateString) return false;
	const date = new Date(dateString);
	const now = new Date();
	const diffDays = dateDiffInDays(now, date);

	return diffDays > 90;
};

interface MessageOperationsProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	message?: DatoveSchrankyDatovaZpravaDto;
	updateLoadingItems: (
		loadingItem: DataBoxMessageDetailLoadingItemType,
		isLoading: boolean
	) => void;
	setConfirmModal: (confirmModal: Nullable<DataBoxConfirmModalType>) => void;
}

export const useMessageOperations = ({
	dataBoxId,
	folderType,
	messageId,
	message,
	updateLoadingItems,
	setConfirmModal,
}: MessageOperationsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);
	const queryClient = useQueryClient();

	const { storageRefetch } = useDataBoxEvents();
	const { toastMessage } = useMessageEvents();
	const { messagesArchive, messageListUpdate } = useDataBoxEvents();

	const archivingSettingsQuery = useArchivingSettingsQuery(dataBoxId);

	const isArchivingEnabled =
		archivingSettingsQuery.data?.hodnota ===
			DatovkaNastaveniArchivaceValue.LargeDataboxMessage &&
		(message ? !isReceivedBefore90Days(message) : false);

	const archiveMessage = usePoMutation({
		mutationFn: archiveDataBoxesMessageQuery,
		onSuccess: () => {
			messagesArchive({ messageIds: [messageId] });
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, false);
			storageRefetch();

			toastMessage({
				content: tn('akce.zprava-zkopirovana'),
				options: {
					variant: 'success',
					icon: {
						name: 'archive-outline',
						type: 'basic',
					},
				},
			});
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, false);
		},
	});

	const handleArchiveMessage = () => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, true);
		archiveMessage.mutate({ dataBoxId, messageId });
	};

	const restoreMessage = usePoMutation({
		mutationFn: restoreDataBoxesMessageQuery,
		onSuccess: (_, variables) => {
			messageListUpdate();
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, false);

			toastMessage({
				content: tn('akce.zprava-obnovena'),
				options: {
					variant: 'success',
					icon: {
						name: 'trash-restore',
						type: 'basic',
					},
				},
			});

			variables.redirect();
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, false);
		},
	});

	const handleRestoreMessage = (redirect: () => void) => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, true);
		restoreMessage.mutate({ messageId, redirect });
	};

	const deleteMessage = usePoMutation({
		mutationFn: deleteDataBoxesMessageQuery,
		onSuccess: (_, variables) => {
			messageListUpdate();
			storageRefetch();

			updateLoadingItems(
				variables.permanently
					? DataBoxMessageDetailLoadingItemType.RemovePermanently
					: DataBoxMessageDetailLoadingItemType.Remove,
				false
			);

			setConfirmModal(null);

			toastMessage({
				content: variables.permanently
					? tn('akce.zprava-trvale-smazana')
					: tn('akce.zprava-smazana-z-archivu'),
				options: {
					variant: 'success',
					icon: {
						name: 'trash',
						type: 'basic',
					},
				},
			});

			variables.redirect();
		},
		onError: async (_, variables) => {
			updateLoadingItems(
				variables.permanently
					? DataBoxMessageDetailLoadingItemType.RemovePermanently
					: DataBoxMessageDetailLoadingItemType.Remove,
				false
			);
		},
	});

	const handleDeleteMessage = (permanently = false, redirect: () => void) => {
		updateLoadingItems(
			permanently
				? DataBoxMessageDetailLoadingItemType.RemovePermanently
				: DataBoxMessageDetailLoadingItemType.Remove,
			true
		);
		deleteMessage.mutate({ messageId, permanently, redirect });
	};

	const { downloadDataBoxMessage } = useDownloadEvents();

	const handleDownloadMessage = (message: DatoveSchrankyDatovaZpravaDto) => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Download, true);

		downloadDataBoxMessage({
			messageData: { dataBoxId, message, folderType },
			callback: () => {
				updateLoadingItems(DataBoxMessageDetailLoadingItemType.Download, false);
			},
		});
	};

	const saveAllAttachmentsToDocuments = useSaveAllAttachmentsToDocuments({
		onError: () => {
			updateLoadingItems(
				DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments,
				false
			);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['documents'] });

			updateLoadingItems(
				DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments,
				false
			);
			storageRefetch();
		},
	});

	const handleSaveAllAttachmentsToDocuments = (
		message: DatoveSchrankyDatovaZpravaDto
	) => {
		updateLoadingItems(
			DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments,
			true
		);

		saveAllAttachmentsToDocuments.mutate({
			attachments: message.prilohy ?? [],
			dataBoxId,
			messageId,
			folderType,
		});
	};

	return {
		isLoading: archivingSettingsQuery.isLoading,
		isArchivingEnabled,
		handleArchiveMessage,
		handleRestoreMessage,
		handleDeleteMessage,
		handleDownloadMessage,
		handleSaveAllAttachmentsToDocuments,
	};
};
