import { Configuration } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';
import { hasOwnProperty } from '@gov-nx/core/types';
import { useConfigurationStore } from '@gov-nx/store/portal-obcana';

export const useConfiguration = (): Configuration => {
	const { configuration } = useConfigurationStore();

	if (!configuration) {
		throw new GovError('[GOV] configuration not loaded yet');
	}

	Object.keys(configuration).forEach((key: string) => {
		if (hasOwnProperty(configuration, key) && configuration[key] === 'null') {
			configuration[key] = null;
		}
	});

	return configuration;
};
