import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DefaultUploadParameters } from '@gov-nx/core/hooks';
import {
	LocalizeNameSpaceTypes,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode, useVlastniDokladyContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	ContentLayout,
	FormDatePicker,
	FormFileUpload,
	FormInput,
	FormSwitch,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';
import { PoMarkdownRenderWeb } from '../../../Markdown/PoMarkdownRenderWeb';
import { OwnDocumentFormSkeleton } from './OwnDocumentFormSkeleton';

interface EditOwnDocumentFormProps {
	code: PageCode;
}

export const OwnDocumentForm = ({ code }: EditOwnDocumentFormProps) => {
	const {
		documentId,
		controls,
		isEditAction,
		formDefinition,
		documentPreviewQuery,
		replaceFile,
		onSubmit,
		hasNotFinishedUploads,
		onFilesChanged,
	} = useVlastniDokladyContext();
	const { getLocalizeCurried, t } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const urlCreator = window.URL || window.webkitURL;
	const previewImage = documentPreviewQuery.documentPreviewQuery.data;

	if (controls.initialLoading) {
		return <OwnDocumentFormSkeleton />;
	}

	return (
		<ContentLayout>
			<FormProvider {...formDefinition.formMethods}>
				<PoFormWeb
					formDefinition={formDefinition}
					onSubmit={onSubmit}>
					<LayoutGap gap={6}>
						<FormWidthElementLayout type="l">
							<FormInput
								field={{
									name: 'nazev',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholder.nazev'),
								}}
								label={{
									children: tsn('formular.pole.nazev'),
								}}
							/>
						</FormWidthElementLayout>

						{isEditAction && previewImage && (
							<FormSwitch
								field={{
									name: '_replaceFile',
									label: { children: tsn('formular.pole.vymenit-soubor') },
								}}
							/>
						)}

						{(replaceFile || !isEditAction || !previewImage) && (
							<FormFileUpload
								field={{ name: 'soubor', expanded: true }}
								// label={{ children: tsn('formular.pole.soubor') }}
								options={{
									...DefaultUploadParameters,
									extensions: ['.jpg', '.jpeg', '.png', '.pdf'],
									onFilesChanged: onFilesChanged,
								}}
							/>
						)}

						<FormWidthElementLayout type="l">
							<LayoutGap gap={6}>
								<FormInput
									field={{
										name: 'cisloDokumentu',
										disabled: controls.processLoading,
										placeholder: tsn('formular.placeholder.cislo-dokumentu'),
									}}
									label={{
										children: tsn('formular.pole.cislo-dokumentu'),
									}}
								/>

								<FormDatePicker
									field={{
										name: 'platnostOd',
										disabled: controls.processLoading,
									}}
									label={{
										children: tsn('formular.pole.platnost-od'),
									}}
								/>
								<FormDatePicker
									field={{
										name: 'platnostDo',
										disabled: controls.processLoading,
									}}
									label={{
										children: (
											<PoMarkdownRenderWeb>
												{tsn('formular.pole.platnost-do')}
											</PoMarkdownRenderWeb>
										),
									}}
								/>
							</LayoutGap>
						</FormWidthElementLayout>
						<FormInput
							field={{
								name: 'komentar',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholder.komentar'),
								multiline: true,
								rows: 4,
							}}
							label={{
								children: tsn('formular.pole.komentar'),
							}}
						/>

						{isEditAction &&
							formDefinition.formMethods.getValues('nazevSouboru') && (
								<>
									{documentPreviewQuery.documentPreviewQuery.isLoading ? (
										<div>
											<GovSkeleton
												height={'22px'}
												width={'150px'}
												wcagLabel={
													t('loading.wcag.processing', {
														namespace: LocalizeNameSpaceTypes.Global,
													}) as string
												}
											/>
										</div>
									) : (
										<div>
											{documentId &&
											documentPreviewQuery.isPreviewNotAvailable(documentId)
												? tsn('vlastni-doklady.nahled-neni')
												: null}
											{previewImage && (
												<div>
													<img src={urlCreator.createObjectURL(previewImage)} />
												</div>
											)}
										</div>
									)}
								</>
							)}

						<ButtonGroupLayout>
							<Button
								nativeType="submit"
								wcagLabel={
									isEditAction
										? tsn('formular.akce.ulozit')
										: tsn('formular.akce.pridat')
								}
								disabled={controls.processLoading || hasNotFinishedUploads}
								variant="primary"
								type="solid">
								{isEditAction
									? tsn('formular.akce.ulozit')
									: tsn('formular.akce.pridat')}
							</Button>
						</ButtonGroupLayout>
					</LayoutGap>
				</PoFormWeb>
			</FormProvider>
		</ContentLayout>
	);
};
