import { GovIcon } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';
import { useLocale } from '@gov-nx/core/service';
import { NotificationsContexts, PageCode } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	DataTable,
	LayoutGap,
} from '@gov-nx/ui/web';
import { toDateReadable, sanitizedText } from '@gov-nx/utils/common';
import { NotificationsSkeleton } from './NotificationsSkeleton';

interface ModalViewProps {
	selectedNotification: NotificationsContexts['selectedNotification'];
	code: PageCode;
	children: ReactNode;
}

export const ModalView = ({
	selectedNotification,
	code,
	children,
}: ModalViewProps) => {
	const { t: ts } = useLocale(code);
	const { isFetching, detail, links, poLinks } = selectedNotification;

	const cleanedText = sanitizedText(links, detail?.popisDlouhy);

	return (
		<LayoutGap>
			{isFetching ? (
				<NotificationsSkeleton />
			) : (
				<>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{ts('zaznam.termin')}</td>
								<td>
									<strong>
										{detail?.datumACasVytvoreni
											? toDateReadable(detail?.datumACasVytvoreni)
											: '-'}
									</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.odesilatel')}</td>
								<td>
									<strong>{detail?.odesilatelNazev ?? '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.predmet')}</td>
								<td>
									<strong dangerouslySetInnerHTML={{ __html: cleanedText }} />
								</td>
							</tr>
							{detail?.rozsirujiciInformace?.statusText ? (
								<tr>
									<td>{ts('zaznam.stav')}</td>
									<td>
										<strong>{detail.rozsirujiciInformace.statusText}</strong>
									</td>
								</tr>
							) : null}
						</tbody>
					</DataTable>
					<ButtonGroupLayout>
						{poLinks?.map((linkData, index) => (
							<Button
								type="solid"
								variant="primary"
								key={index}
								href={linkData?.link || ''}>
								{linkData?.label}
							</Button>
						))}
						{links?.map((linkData, index) => (
							<Button
								enforceLink={true}
								href={linkData?.href || ''}
								target={'_blank'}
								type="solid"
								variant="primary"
								key={index}>
								{ts('akce.prejit-na-web')}
								<GovIcon name="box-arrow-up-right" />
							</Button>
						))}
						{children}
					</ButtonGroupLayout>
				</>
			)}
		</LayoutGap>
	);
};
