import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	OsobyFotoDto,
	OsobyFyzickaOsobaDto,
	filterErrorStatus,
	robPersonPhotoQuery,
	robPersonQuery,
	usePoQueries, ResponseError,
} from '@gov-nx/api/portal-obcana';
import { isResponseStatus } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useProcessControl } from '@gov-nx/core/hooks';
import { is, Nullable } from '@gov-nx/core/types';
import { ProfileROBContext, ProfileRobProcessControl } from './context.types';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const ProfilROBContext = createContext<Nullable<ProfileROBContext>>(null);

interface ProfilROBContextProviderProps {
	children: React.ReactNode;
}

export function ProfilROBContextProvider({
	children,
}: ProfilROBContextProviderProps) {
	const { setControls, controls } = useProcessControl<ProfileRobProcessControl>(
		{
			initialDataError: null,
			initialPhotoError: null,
			displayPhoto: false,
			displayComplaint: false,
		}
	);
	const [data, setData] = useState<Nullable<OsobyFyzickaOsobaDto>>(null);
	const [photo, setPhoto] = useState<Nullable<OsobyFotoDto['data']>>(null);

	const mainQueries = usePoQueries<[
			UseQueryResult<OsobyFyzickaOsobaDto, AxiosError<ResponseError>>,
			UseQueryResult<OsobyFotoDto, AxiosError<ResponseError>>
		]>({
		queries: [
			{
				queryKey: ['profile-rob'],
				queryFn: robPersonQuery,
				onError: (error: Error) => {
					setControls({ initialDataError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['profile-rob-query'],
				queryFn: robPersonPhotoQuery,
				onError: (error: Error) => {
					setControls({ initialPhotoError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
		],
		configs: [
			{
				errorIgnoreFilter: filterErrorStatus(500, (initialError) =>
					setControls({ initialError })
				),
			},
			{
				errorIgnoreFilter: isResponseStatus(404),
			},
		],
	});
	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);
	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		setControls({ initialLoading: true });
	}, []);

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const robData = queriesResponses[0]
				? (queriesResponses[0] as OsobyFyzickaOsobaDto)
				: null;
			const robPhoto = queriesResponses[1]
				? (queriesResponses[1] as OsobyFotoDto)
				: null;

			setData(robData);

			setPhoto(robPhoto ? robPhoto.data : null);
			setControls({ initialLoading: false });
		}
	}, [isQueriesFetched]);

	return (
		<ProfilROBContext.Provider
			value={{
				controls,
				setControls,
				data,
				photo,
			}}>
			{children}
		</ProfilROBContext.Provider>
	);
}

export const useProfilROBContextInstance = (): ProfileROBContext =>
	useContext(ProfilROBContext) as ProfileROBContext;
