import * as yup from 'yup';
import {
	CommunicationType,
	ContactDataDataRequest,
	ContactVerificationDataDataRequest,
	EmailFormData,
	PhoneFormData,
} from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import {
	phoneShapeToString,
	usePhoneShapeWithCountryCodes,
} from '@gov-nx/utils/common';

export interface EmailFormInstanceProps {
	isNeedForVerification: boolean;
}

export function EmailFormInstance({
	isNeedForVerification,
}: EmailFormInstanceProps): FormDefinition<EmailFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const code = PageCode['nastaveni'];
	const tsn = getLocalizeCurried(code);
	const { getLocalize } = useTranslationWithNamespace();

	const formSchema = yup
		.object<FormSchemaShape<EmailFormData>>({
			email: yup
				.string()
				.required(getLocalize(code, 'form.validations.zadejte-kontaktni-email'))
				.email(
					getLocalize(code, 'form.validations.email-neni-ve-spravnem-tvaru')
				),
			code: yup
				.string()
				.optional()
				.when([], {
					is: () => isNeedForVerification,
					then: (schema) => schema.required(tsn('formular.validace.kod')),
				}),
		})
		.required();

	const formMethods = usePoForm<EmailFormData>({
		formSchema,
		defaultValues: {
			email: undefined,
			code: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export interface PhoneFormInstanceProps {
	isNeedForVerification: boolean;
}

export function PhoneFormInstance({
	isNeedForVerification,
}: PhoneFormInstanceProps): FormDefinition<PhoneFormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const code = PageCode['nastaveni'];

	const tsn = getLocalizeCurried(code);
	const { getPhoneShape, getDefaultValue } = usePhoneShapeWithCountryCodes();

	const formSchema = yup
		.object<FormSchemaShape<PhoneFormData>>({
			telefon: getPhoneShape({ isRequired: true }),
			code: yup
				.string()
				.optional()
				.when([], {
					is: () => isNeedForVerification,
					then: (schema) => schema.required(tsn('formular.validace.kod')),
				}),
		})
		.required();

	const formMethods = usePoForm<PhoneFormData>({
		formSchema,
		defaultValues: {
			telefon: getDefaultValue(),
			code: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareUpdateEmailFormSubmitData = (
	ico: Optional<number>,
	source: 'ROB' | 'ROS',
	{ email }: Pick<EmailFormData, 'email'>
): ContactDataDataRequest => {
	return {
		body: {
			zdroj: source,
			ico,
			hodnota: email,
			typ: CommunicationType.EMAIL,
		},
	};
};

export const prepareVerifyEmailFormSubmitData = (
	ico: Optional<number>,
	source: 'ROB' | 'ROS',
	formData: Pick<EmailFormData, 'email' | 'code'>
): ContactVerificationDataDataRequest => {
	return {
		body: {
			zdroj: source,
			ico,
			hodnota: formData.email,
			typ: CommunicationType.EMAIL,
			kod: formData.code ?? '',
		},
	};
};

export const prepareUpdatePhoneFormSubmitData = (
	ico: Optional<number>,
	source: 'ROB' | 'ROS',
	formData: Pick<PhoneFormData, 'telefon'>
): ContactDataDataRequest => {
	return {
		body: {
			zdroj: source,
			ico,
			hodnota: phoneShapeToString(formData.telefon),
			typ: CommunicationType.TELEFON,
		},
	};
};

export const prepareVerifyPhoneFormSubmitData = (
	ico: Optional<number>,
	source: 'ROB' | 'ROS',
	formData: PhoneFormData
): ContactVerificationDataDataRequest => {
	return {
		body: {
			zdroj: source,
			ico,
			hodnota: phoneShapeToString(formData.telefon),
			typ: CommunicationType.TELEFON,
			kod: formData.code ?? '',
		},
	};
};

export const prepareDeleteFormSubmitData = (
	ico: Optional<number>,
	source: 'ROB' | 'ROS',
	typ: CommunicationType
): ContactDataDataRequest => {
	return {
		body: {
			zdroj: source,
			ico,
			hodnota: '',
			typ,
		},
	};
};
