import { routeResolver } from '@gov-nx/web';
import React from 'react';
import { Link } from 'react-router-dom';
import { OsobySouhlasDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuSeznamContext } from '@gov-nx/module/page';
import {
	Button,
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
} from '@gov-nx/ui/web';
import { ConsentManagementSkeleton } from './ConsentManagementSkeleton';

interface ConsentManagementProps {
	code: PageCode;
}

interface RecallButtonProps {
	code: PageCode;
	item: OsobySouhlasDto;
}

const RecallButton = ({ code, item }: RecallButtonProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { handleSubmit, controls } = useSpravaSouhlasuSeznamContext();

	return (
		<Button
			onClick={() => handleSubmit(item)}
			loading={controls.consentLoadingId === item.id}
			disabled={controls.processLoading}
			wcagLabel={tsn('akce.tlacitko.wcag.odvolat-souhlas') as string}
			nativeType="button"
			variant="error"
			size="s"
			type="base"
			focusable={false}>
			{tsn('akce.tlacitko.odvolat-souhlas')}
		</Button>
	);
};

export const ConsentManagementView = ({ code }: ConsentManagementProps) => {
	const { consentData, controls } = useSpravaSouhlasuSeznamContext();

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <ConsentManagementSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	if (Array.isArray(consentData) && consentData.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'empty-file', type: 'colored' }}
				type="secondary">
				<p className={'text-2xl'}>{tsn('stav.chyba.zadny-zaznam')}</p>
				<p>{tsn('stav.chyba.nic-jsme-nenasli')}</p>
			</InfoBanner>
		);
	}

	return (
		<ContentLayout>
			<ul className={'gov-list--plain'}>
				{consentData?.map((item, index) => (
					<li
						key={index}
						className={
							'-mx-5 py-6 px-4 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ]'
						}>
						<div className={'flex justify-between'}>
							<Link
								to={routeResolver(PageCode['sprava-souhlasu-detail'], {
									consentId: item.id,
								})}>
								<h3
									className={
										'inline-block pr-6 mb-2 text-xl underline [ hover:no-underline ]'
									}>
									{item.nazevOsoby}
								</h3>
							</Link>
							<div className={'hidden [ sm:block ]'}>
								<RecallButton
									code={code}
									item={item}
								/>
							</div>
						</div>
						<p className={'block text-secondary-700 [ sm:inline ]'}>
							{tsn('pole.udaje.id-souhlasu')}: <strong>{item.id}</strong>
						</p>
						<div className={'pt-2 block [ sm:hidden ]'}>
							<RecallButton
								code={code}
								item={item}
							/>
						</div>
					</li>
				))}
			</ul>
		</ContentLayout>
	);
};
