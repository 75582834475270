import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import {
	CiselnikyVolbyDto,
	PodaniPodaniInstanceDto,
	PodaniPodaniInstanceSeznamDto,
} from '../generated';

export const mySubmissionsQuery = async <Params>(
	params: Params
): Promise<PodaniPodaniInstanceSeznamDto> => {
	govApiLog('/api/v1/podaniinstance');
	const { data } = await axiosInstance.get('/api/v1/podaniinstance', {
		params,
	});
	return data;
};

const searchOnFE = async <Params>(
	params: Params,
	search: string,
	start = 0,
	buffer: PodaniPodaniInstanceSeznamDto = { seznam: [], pocet: 0 }
): Promise<PodaniPodaniInstanceSeznamDto> => {
	const pocet = 500;
	const result = await mySubmissionsQuery({
		...params,
		start,
		pocet,
	});

	const filtered =
		result.seznam?.filter((item) =>
			item.nazev?.toLowerCase().includes(search.toLowerCase())
		) ?? [];

	const newBuffer: PodaniPodaniInstanceSeznamDto = {
		seznam: [...(buffer.seznam ?? []), ...filtered],
		pocet: (buffer.pocet ?? 0) + filtered.length,
	};

	if (start >= (result.pocet ?? 0)) {
		return newBuffer;
	}
	return searchOnFE(params, search, start + pocet, newBuffer);
};

export const mySubmissionsSearchQuery = async <
	Params extends { start?: number; pocet?: number }
>(
	search: string,
	params: Params
): Promise<PodaniPodaniInstanceSeznamDto> => {
	const result = await searchOnFE(params, search);
	const start = params.start ?? 0;
	const pocet = params.pocet ?? 10;

	return {
		pocet: result.pocet,
		seznam: result.seznam?.slice(start, start + pocet),
	};
};

export interface MySubmissionVoterCardAplication
	extends PodaniPodaniInstanceDto {
	kodPodani: 'ZADOST_VOLEBNI_PRUKAZ';
	parametry: {
		druh: CiselnikyVolbyDto['druh'];
		kod: string;
		kolo1datumDo: string;
		kolo1datumOd: string;
		kolo2datumDo?: string;
		kolo2datumOd?: string;
		kolo1zadost?: 'true' | 'false';
		kolo2zadost?: 'true' | 'false';
		kontaktniEmail?: string; //'neuveden';
		kontaktniTelefon?: string; //'neuveden';
		kraj?: string; //'Hlavní město Praha';
		uradAdresa?: string; //'Dominikánská 264/2, Brno-město, 60200 Brno';
		uradNazev?: string; //'Městská část Brno-střed';
		uradOdesleNaAdresu?: string; //'Přemyslova 158/4, Vyšehrad, 128 00 Praha';
		uradOdesleNejdrive?: string; //'2024-05-23';
		volebniObvod: null;
		volebniObvodKod?: string; //'2032';
		vyzvednutiNaUradu?: string; //'Městská část Brno-střed, Dominikánská 264/2, Brno-město, 60200 Brno';
		vyzvednutiNaUraduDatumDo?: string; //'2024-06-06';
		vyzvednutiNaUraduDatumOd?: string; //'2024-05-23';
		zpusobPrevzeti: 'POSTA' | 'POSTA_JINA_ADRESA' | 'URAD' | 'URAD_ZAHRANICNI';
		obcanEu?: unknown; // netusim co tu chodi za hodnoty
		zastupitelskyUradAdresa?: string;
		zastupitelskyUradNazev?: string;
	};
}

export interface MySubmission_EGSB_2 extends PodaniPodaniInstanceDto {
	kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2';
	parametry: {
		email?: string;
		typOsoby?: string;
		proKontaktSDetmi?: 'true' | 'false';
		vypisZeZahranici?: string;
	};
}

export type MySubmission =
	| PodaniPodaniInstanceDto
	| MySubmissionVoterCardAplication;
export const isVoterRegistrationSubmission = (
	submission: MySubmission
): submission is MySubmissionVoterCardAplication =>
	submission.kodPodani === 'ZADOST_VOLEBNI_PRUKAZ';

export const isVypisEgsbSubmission = (
	submission: MySubmission
): submission is MySubmission_EGSB_2 =>
	submission.kodPodani === 'REJSTRIK_TRESTU_VYPIS_EGSB_2';

export const mySubmissionDetailQuery = async (
	submissionId: number
): Promise<MySubmission> => {
	govApiLog('/api/v1/podaniinstance/' + submissionId);
	const { data } = await axiosInstance.get(
		`/api/v1/podaniinstance/${submissionId}`
	);
	// hodi se pro takovyto lokalni mockovani
	// return {
	// 	id: 23024,
	// 	kodPodani: 'ZADOST_VOLEBNI_PRUKAZ',
	// 	nazev: '[23024] - Žádost o voličský průkaz, Volby do Evropského parlamentu',
	// 	stav: 'Podaná žádost',
	// 	datumOdeslani: '2024-06-06T12:08:26Z',
	// 	datumVyrizeni: '2024-06-06T12:08:26Z',
	// 	lhutaVyrizeni: '2024-06-06T12:08:26Z',
	// 	automatickeZpracovani: false,
	// 	datoveZpravy: [
	// 		{
	// 			datovaZpravaId: 11192614,
	// 			datovaSchrankaId: 'shqibw4',
	// 		},
	// 	],
	// 	dokumenty: [],
	// 	parametry: {
	// 		kraj: 'Ústecký kraj',
	// 		volebniObvod: null,
	// 		volebniObvodKod: '1',
	// 		vyzvednutiNaUradu:
	// 			'Městská část Brno-střed, Dominikánská 264/2, Brno-město, 60200 Brno',
	// 		uradNazev: 'Městská část Brno-střed',
	// 		uradAdresa: 'Dominikánská 264/2, Brno-město, 60200 Brno',
	// 		vyzvednutiNaUraduDatumOd: '2024-06-06',
	// 		vyzvednutiNaUraduDatumDo: '2024-06-20',
	// 		uradOdesleNaAdresu: 'č.ev. 1, 407 14 Arnoltice',
	// 		kod: 'EP_202406061406',
	// 		druh: 'POSLANECKA_SNEMOVNA',
	// 		kolo1datumOd: '2024-06-21',
	// 		kolo1datumDo: '2024-06-23',
	// 		zpusobPrevzeti: 'URAD',
	// 		kontaktniEmail: 'neuveden',
	// 		kontaktniTelefon: 'neuveden',
	// 	},
	// };

	return data;
};
