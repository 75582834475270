import { useEffect, useState } from 'react';
import { axiosCmsInstance } from '@gov-nx/api/common';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { Optional } from '@gov-nx/core/types';
import { useConfiguration } from '../configuration/useConfiguration';
import { initTranslations } from './i18n';

interface TranslationInitProps {
	initialLanguage: LocalizationLanguage;
	onError: (errorMessage: string) => void;
}

export const useTranslationInit = ({
	initialLanguage,
	onError,
}: TranslationInitProps): { translationInitialized: boolean } => {
	const [translationInitialized, setTranslationInitialized] =
		useState<Optional<LocalizationLanguage>>(undefined);
	const { cmsKeyCache } = useConfiguration();

	useEffect(() => {
		if (translationInitialized && translationInitialized === initialLanguage) {
			return;
		}

		initTranslations(initialLanguage, axiosCmsInstance, cmsKeyCache)
			.then(() => setTranslationInitialized(initialLanguage))
			.catch((error) => {
				setTranslationInitialized(undefined);
				onError(`Chyba nacitani lokalizace: ${(error as Error).message}`);
			});
	}, [initialLanguage, translationInitialized]);

	return { translationInitialized: !!translationInitialized };
};
