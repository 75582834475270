import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import {
	OsobyKomunikacniUdajeRosSeznamDto,
	OsobyPodnikatelSeznamDto,
	OsobyPodnikatelSouhlasDto,
} from '../generated';

export const rosCommunicationQuery =
	async (): Promise<OsobyKomunikacniUdajeRosSeznamDto> => {
		govApiLog('/api/v1/podnikatele/komunikace');
		const { data } = await axiosInstance.get('/api/v1/podnikatele/komunikace');
		return data;
	};

export const rosBusinessPersonQuery =
	async (): Promise<OsobyPodnikatelSeznamDto> => {
		govApiLog('/api/v1/podnikatele');
		const { data } = await axiosInstance.get('/api/v1/podnikatele');
		return data;
	};

export const rosBusinessPersonAgreementQuery = async <Params>(
	params: Params
): Promise<OsobyPodnikatelSouhlasDto> => {
	govApiLog('/api/v1/podnikatele/souhlas');
	const { data } = await axiosInstance.get('/api/v1/podnikatele/souhlas', {
		params,
	});
	return data;
};
