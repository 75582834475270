import React from 'react';
import { CopyButton, DataTable } from '@gov-nx/ui/web';

interface TableProps {
	data: [
		label: string,
		value?: string,
		options?: { copyValueLabel?: string }
	][];
}

export const Table = ({ data }: TableProps) => {
	return (
		<DataTable responsive>
			<tbody>
				{data.map(([label, value, options], index) => {
					return (
						<tr key={index}>
							<td>{label}</td>
							<td>
								<strong>{value || '-'}</strong>
								{value && options?.copyValueLabel && (
									<CopyButton
										value={value}
										label={options.copyValueLabel}
									/>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</DataTable>
	);
};
