import { routeResolver, RouteType } from '@gov-nx/web';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useRegistrRidicuContext } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import {
	DataTable,
	InfoBanner,
	Modal,
	Space,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';
import { useWindowDimensions } from '@gov-nx/utils/web';

const MOBILE_WIDTH_MAX = 768;

export interface DriversRegistryOffencesViewProps {
	code: PageCode;
}

export const DriversRegistryOffencesView = ({
	code,
}: DriversRegistryOffencesViewProps) => {
	const { data, selectedOffence, selectOffence } = useRegistrRidicuContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { width } = useWindowDimensions();
	const isMobile = width < MOBILE_WIDTH_MAX;
	console.log(data);
	/* @TODO color points from one source getDriversPointsColor */
	const statusClassName =
		data?.points?.state === 'good'
			? 'gov-color--success-500'
			: data?.points?.state === 'warning'
			? 'gov-color--warning-500'
			: data?.points?.state === 'bad'
			? 'gov-color--error-400'
			: '';
	return (
		<>
			<section className={'flex flex-col gap-6'}>
				<Tiles>
					<Tile
						to={routeResolver(
							ServiceCode['vypis-bodoveho-hodnoceni-ridice'],
							null,
							RouteType.SERVICE
						)}
						name={ts('vypis-bodoveho-hodnoceni-ridice')}
						icon={{ name: 'chevron-right', type: 'basic' }}
						customClasses={'[ last-of-type:border-b ]'}>
						<p className={'text-secondary-700'}>
							{ts('aktualni-pocet-bodu')}:{' '}
							<strong className={statusClassName}>
								{data?.points?.current}
							</strong>{' '}
							{ts('bodu-z')} {data?.points?.max}
						</p>
					</Tile>
				</Tiles>

				<section className={'py-2'}>
					<h2 className={'mb-4 text-xl'}>{ts('prestupky')}</h2>
					{data?.hasOffences ? (
						<Tiles>
							{data?.offences?.map((offence, index) => {
								const name = isMobile
									? `${
											offence.offenceDate
												? toDateReadable(offence.offenceDate)
												: ts('data.neurceno')
									  }`
									: `${offence.sanctions.join(', ')} + ${offence.points} ${ts(
											'bodu'
									  )}`;
								const content = isMobile ? (
									<>
										{ts('Sankce')}:<Space />
										<strong>{offence.sanctions.join(', ')}</strong>
										<Space />
										{ts('Body')}: <strong>{offence.points}</strong>
									</>
								) : (
									<>
										{ts('datum')}:<Space />
										{offence.offenceDate
											? toDateReadable(offence.offenceDate)
											: ts('data.neurceno')}
									</>
								);

								return (
									<Tile
										key={index}
										onClick={() => selectOffence(index)}
										name={name}
										icon={{ name: 'chevron-right', type: 'basic' }}>
										<p className={'text-secondary-700'}>{content}</p>
									</Tile>
								);
							})}
						</Tiles>
					) : (
						<InfoBanner
							icon={{ name: 'empty-file', type: 'colored' }}
							type="secondary"
							className={'!px-0 !py-4'}>
							<p className={'text-2xl [ md:text-3xl ]'}>
								{ts('stav.chyba.zadne-prestupky')}
							</p>
							<p>{ts('stav.chyba.zadne-prestupky-popis')}</p>
						</InfoBanner>
					)}
				</section>
			</section>

			<Modal
				label={ts('prestupky')}
				open={!!selectedOffence}
				onGov-close={() => selectOffence()}>
				<DataTable responsive>
					<tbody>
						{selectedOffence?.offenceDate && (
							<tr>
								<td>{ts('data.datum-spachani')}</td>
								<td>
									<strong>{toDateReadable(selectedOffence.offenceDate)}</strong>
								</td>
							</tr>
						)}
						{selectedOffence?.decisionDate && (
							<tr>
								<td>{ts('data.rozhodnuti-nabylo-pravni-moci')}</td>
								<td>
									<strong>
										{toDateReadable(selectedOffence.decisionDate)}
									</strong>
								</td>
							</tr>
						)}
						{selectedOffence?.decisionBureau && (
							<tr>
								<td>{ts('data.rozhodl')}</td>
								<td>
									<strong>{selectedOffence.decisionBureau}</strong>
								</td>
							</tr>
						)}
						{selectedOffence?.sanctions &&
							selectedOffence.sanctions.length > 0 && (
								<tr>
									<td>{ts('data.duvod')}</td>
									<td>
										<strong>{selectedOffence.sanctions.join(', ')}</strong>
									</td>
								</tr>
							)}
						{selectedOffence?.violation && (
							<tr>
								<td>{ts('data.porusena-ustanoveni')}</td>
								<td>
									<strong>{selectedOffence.violation}</strong>
								</td>
							</tr>
						)}
						{selectedOffence?.offence && (
							<tr>
								<td>{ts('data.spachane-prestupky')}</td>
								<td>
									<strong>{selectedOffence.offence}</strong>
								</td>
							</tr>
						)}
						{selectedOffence?.points !== undefined && (
							<tr>
								<td>{ts('data.body')}</td>
								<td>
									<strong>{selectedOffence.points}</strong>
								</td>
							</tr>
						)}
					</tbody>
				</DataTable>
			</Modal>
		</>
	);
};

DriversRegistryOffencesView.displayName = 'DriversRegistryOffencesView';
