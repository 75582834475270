import { GovChip } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useSpravaSouhlasuDetailContext } from '@gov-nx/module/page';
import { BadgeNav, ContentLayout, CopyButton, DataTable } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

interface ConsentManagementUsersProps {
	code: PageCode;
}

export const ConsentManagementUsers = ({
	code,
}: ConsentManagementUsersProps) => {
	const { filteredDetail, individualBadges, view, person, idDocuments } =
		useSpravaSouhlasuDetailContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<ContentLayout customClasses={'!pt-4'}>
			<BadgeNav>
				{individualBadges.map((badge, index: number) => (
					<li key={index}>
						<GovChip
							variant={'primary'}
							size="m"
							tag="button"
							inverse={!badge.isActive}
							wcagLabel={`${tsn('akce.wcag.filtrovat')} ${badge.name}`}
							onGov-click={() => badge.viewFunction()}>
							{badge.name}
						</GovChip>
					</li>
				))}
			</BadgeNav>
			{view.prijemce && (
				<DataTable responsive>
					<tbody>
						<tr>
							<td>{tsn('tabulka.udaje.firma-nazev')}</td>
							<td>
								<strong>{filteredDetail?.nazevOsoby}</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.ico')}</td>
							<td>
								<strong>
									{filteredDetail?.ico ? filteredDetail?.ico : '-'}
								</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.datova-schranka')}</td>
							<td>
								<strong>{filteredDetail?.datovaSchrankaId}</strong>
								<CopyButton
									label={
										tsn('akce.kopirovat-cislo-dokladu') +
										' ' +
										filteredDetail?.datovaSchrankaId
									}
									value={filteredDetail?.datovaSchrankaId as string}
								/>
							</td>
						</tr>
					</tbody>
				</DataTable>
			)}
			{view.zadatel && (
				<DataTable responsive>
					<tbody>
						<tr>
							<td>{tsn('tabulka.udaje.jmeno')}</td>
							<td>
								<strong>{person?.jmeno}</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.prijmeni')}</td>
							<td>
								<strong>{person?.prijmeni}</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.datum-narozeni')}</td>
							<td>
								<strong>
									{person?.datumNarozeni &&
										toDateReadable(person.datumNarozeni)}
								</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.druh-dokladu')}</td>
							<td>
								<strong>
									{idDocuments[0]?.druhDokladu === 'ID' &&
										tsn('tabulka.udaje.druh-dokladu-id')}
								</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.cislo-dokladu')}</td>
							<td>
								<strong>{idDocuments[0]?.cisloDokladu}</strong>
							</td>
						</tr>
						<tr>
							<td>{tsn('tabulka.udaje.adresa-pobytu')}</td>
							<td>
								<strong>{person?.adresaPobytu?.adresaText}</strong>
							</td>
						</tr>
					</tbody>
				</DataTable>
			)}
			{view.dalsiInformace && tsn('tabulka.udaje.dalsi-informace')}
		</ContentLayout>
	);
};
