import { GovIcon } from '@gov-design-system-ce/react';
import { OsobyFotoDto } from '@gov-nx/api/portal-obcana';
import {
	useConfiguration,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { Button, LayoutGap } from '@gov-nx/ui/web';

export interface ComplaintProps {
	code: PageCode;
	photo: Nullable<OsobyFotoDto['data']>;
}

export const ChangePhotoView = ({ code, photo }: ComplaintProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { jakVymenitObcankuUrl } = useConfiguration();
	const ts = getLocalizeCurried(code);

	return (
		<div className={'flex flex-col gap-6 [ md:flex-row ]'}>
			{photo ? (
				<img
					src={`data:image/png;base64, ${photo}`}
					alt=""
					width={330}
					height={330}
				/>
			) : null}
			<LayoutGap gap={4}>
				<h3 className={'text-xl text-secondary-800'}>
					{ts('foto.neni-aktualni')}
				</h3>
				<p>{ts('foto.aktualizace-fotky')}</p>
				<div className={'flex flex-col gap-4'}>
					<Button
						enforceLink={true}
						href={jakVymenitObcankuUrl}
						variant={'primary'}
						type={'link'}
						target={'_blank'}
						wcagLabel={ts('foto.vymenit-obcanku')}>
						{ts('foto.vymenit-obcanku')}
						<GovIcon
							name={'box-arrow-up-right'}
							slot={'right-icon'}
						/>
					</Button>
				</div>
			</LayoutGap>
		</div>
	);
};
