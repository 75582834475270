import { GovChip } from '@gov-design-system-ce/react';
import { RouteType, routeResolver } from '@gov-nx/web';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useRegistrZPContext } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import {
	BadgeNav,
	ContentLayout,
	ErrorStatusBanner,
	InfoBanner,
	Tile,
	Tiles,
} from '@gov-nx/ui/web';
import { RegisterZPSkeleton } from './RegisterZPSkeleton';

export const RegisterZPView = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { code, badges, setActiveBadge, subjects, controls } =
		useRegistrZPContext();

	const tsn = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <RegisterZPSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<ContentLayout customClasses={'!pt-4'}>
			<BadgeNav wcagLabel={tsn('filtr.stav-subjektu')}>
				{badges.map((badge, index) => {
					return (
						<li key={index}>
							<GovChip
								variant={'primary'}
								size="m"
								tag={'button'}
								inverse={!badge.isActive}
								wcagLabel={`${tsn('filtr.zobrazit-subjekty-ve-stavu')}: ${tsn(
									badge.nameCode
								)}`}
								onGov-click={() => setActiveBadge(index)}>
								{tsn(badge.nameCode)}
							</GovChip>
						</li>
					);
				})}
			</BadgeNav>
			{subjects.length ? (
				<Tiles>
					{subjects.map((subject, index) => {
						return (
							<Tile
								key={index}
								name={subject.name}
								target={'_blank'}
								to={`https://www.rzp.cz/cgi-bin/portal.cgi?ICO=${subject.ic}`}
								headlineLink={true}
								icon={{ name: 'box-arrow-up-right', type: 'basic' }}>
								{subject.status && subject.status !== 'OK' ? (
									<GovChip
										variant={'secondary'}
										size="s">
										{subject.status}
									</GovChip>
								) : null}
								<p className={'!text-secondary-700'}>
									{tsn('subjekt.ic')}: <strong>{subject.ic}</strong>,{' '}
									{tsn('subjekt.role')}: <strong>{subject.roles}</strong>,{' '}
									<a
										href={routeResolver(
											ServiceCode['neverejny-vypis-z-ZR'],
											null,
											RouteType.SERVICE
										)}
										className={
											'gov-color--primary-600 underline [ hover:c-primary-200 ]'
										}>
										{tsn('subjekt.vypis-subjektu')}
									</a>
								</p>
							</Tile>
						);
					})}
				</Tiles>
			) : (
				<InfoBanner
					icon={{ name: 'empty-file', type: 'colored' }}
					type="secondary"
					className={'!px-0 !py-4'}>
					<p className={'text-2xl'}>
						{badges[0].isActive
							? tsn('data.error.zadny-aktivni-zaznam')
							: tsn('data.error.zadny-neaktivni-zaznam')}
					</p>
					<p>
						{badges[0].isActive
							? tsn('data.error.popis-aktivni')
							: tsn('data.error.popis-neaktivni')}
					</p>
				</InfoBanner>
			)}
		</ContentLayout>
	);
};
