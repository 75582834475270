import { useEffect } from 'react';
import {
	OsobyFyzickaOsobaDto,
	OsobyPodnikatelSeznamDto,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useBusinessPersonLoader } from '../Person/useBusinessPersonLoader';
import { usePoIndividualDataLoad } from '../Person/usePoIndividualDataLoad';

export interface Applicant {
	businessPerson: OsobyPodnikatelSeznamDto;
	individualPerson: OsobyFyzickaOsobaDto;
}

interface ApplicantLoaderProps {
	onLoaded: (applicant: Applicant) => void;
	onError: (error: GovError) => void;
}

export const useApplicantLoader = ({
	onLoaded,
	onError,
}: ApplicantLoaderProps): { [key in keyof Applicant]?: Applicant[key] } => {
	const { individualPerson } = usePoIndividualDataLoad({ onError });
	const { businessPerson } = useBusinessPersonLoader({ onError });

	useEffect(() => {
		if (individualPerson && businessPerson) {
			onLoaded({ businessPerson, individualPerson });
		}
	}, [businessPerson, individualPerson]);

	return { businessPerson, individualPerson };
};
