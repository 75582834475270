import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { has } from '@gov-nx/core/types';
import {
	NastaveniKontaktniUdajeROSItemContextProvider,
	PageCode,
	useNastaveniKontaktnichUdajuROSContext,
} from '@gov-nx/module/page';
import { ErrorStatusBanner, InfoBanner } from '@gov-nx/ui/web';
import { ContactListSkeleton } from '../Skeletons';
import { ROSContactView } from './ContactView';

interface ROSContactListViewProps {
	code: PageCode;
}

export const ROSContactListView = ({ code }: ROSContactListViewProps) => {
	const { rosContacts, initialState } =
		useNastaveniKontaktnichUdajuROSContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	if (initialState.isLoading) {
		return <ContactListSkeleton />;
	}

	if (initialState.error) {
		return <ErrorStatusBanner code={code} />;
	}

	const ts = getLocalizeCurried(code);

	if (rosContacts.length === 0) {
		return (
			<InfoBanner
				icon={{ name: 'cactus', type: 'colored' }}
				type="secondary"
				className={'!px-0 !py-4'}>
				<p className={'text-2xl [ md:text-3xl ]'}>
					{ts('stav.chyba.nic-jsme-nenasli')}
				</p>
				<p>{ts('stav.chyba.nenasli-jsme-zadne-udaje')}</p>
			</InfoBanner>
		);
	}

	return (
		<ul className={'gov-list--plain'}>
			{rosContacts.filter(has('ico')).map((contact) => {
				return (
					<li
						key={contact.ico}
						className={'py-4'}>
						<NastaveniKontaktniUdajeROSItemContextProvider
							contact={contact}
							code={code}>
							<ROSContactView code={code} />
						</NastaveniKontaktniUdajeROSItemContextProvider>
					</li>
				);
			})}
		</ul>
	);
};
