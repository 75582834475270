import { useEffect } from 'react';
import * as yup from 'yup';
import { DatovkaNastaveniArchivaceValue } from '@gov-nx/api/portal-obcana';
import {
	useArchivingSettingsQuery,
	useNotificationSettingsQuery,
} from '@gov-nx/api/portal-obcana';
import { usePersonCommunicationData } from '@gov-nx/core/hooks';
import {
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
} from '@gov-nx/core/service';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { useNotificationSettingsValues } from '../upozorneni/utils';

export interface DataboxAdditionalSettingsFormData {
	shouldArchiveAutomatically: boolean;
	shouldArchiveLargeMessagesAutomatically: boolean;
	emailEnabled: boolean;
}

interface DataboxAdditionalSettingsOptions {
	dataBoxId?: string;
	onSuccess: () => void;
}

export const useDataboxAdditionalSettings = ({
	dataBoxId,
	onSuccess,
}: DataboxAdditionalSettingsOptions) => {
	const { emailVerified } = usePersonCommunicationData();
	const { getDataBoxById } = useDataBoxStore();
	const dataBox = getDataBoxById(dataBoxId);

	const formSchema = yup
		.object<FormSchemaShape<DataboxAdditionalSettingsFormData>>({
			shouldArchiveAutomatically: yup.boolean(),
			shouldArchiveLargeMessagesAutomatically: yup.boolean(),
			emailEnabled: yup.boolean(),
		})
		.required();

	const formMethods = usePoForm<DataboxAdditionalSettingsFormData>({
		formSchema,
		defaultValues: {
			shouldArchiveAutomatically: false,
			shouldArchiveLargeMessagesAutomatically: false,
			emailEnabled: false,
		},
	});

	const archivingQuery = useArchivingSettingsQuery(dataBoxId);
	const notificationSettingQuery = useNotificationSettingsQuery();

	useEffect(() => {
		if (archivingQuery.data) {
			const { klic, hodnota } = archivingQuery.data || {};
			if (klic && hodnota === DatovkaNastaveniArchivaceValue.DataBoxMessages) {
				formDefinitions.formMethods.setValue(
					'shouldArchiveAutomatically',
					true
				);
			}
			if (
				klic &&
				hodnota === DatovkaNastaveniArchivaceValue.LargeDataboxMessage
			) {
				formDefinitions.formMethods.setValue(
					`shouldArchiveAutomatically`,
					true
				);
				formDefinitions.formMethods.setValue(
					`shouldArchiveLargeMessagesAutomatically`,
					true
				);
			}
		}
	}, [archivingQuery.data]);

	const settingsValues = useNotificationSettingsValues();

	useEffect(() => {
		if (notificationSettingQuery.data) {
			const { hodnota } = notificationSettingQuery.data;
			const { enabledSms, enabledEmail, enabledEmailAttachment } =
				settingsValues.decompose(hodnota);
			formDefinitions.formMethods.setValue(
				`emailEnabled`,
				enabledEmail || enabledEmailAttachment || enabledSms
			);
		}
	}, [notificationSettingQuery.data]);

	const formDefinitions = getFormDefinition({ formMethods, formSchema });

	const onSubmit = formMethods.handleSubmit(async () => {
		const values = formMethods.getValues();

		await archivingQuery.mutateAsync({
			value: values.shouldArchiveLargeMessagesAutomatically
				? DatovkaNastaveniArchivaceValue.LargeDataboxMessage
				: values.shouldArchiveAutomatically
				? DatovkaNastaveniArchivaceValue.DataBoxMessages
				: null,
		});

		await notificationSettingQuery.mutateAsync({
			value: settingsValues.additionalSettingsCompose(values),
		});

		return onSuccess();
	});

	const isLoading =
		archivingQuery.isMutating || notificationSettingQuery.isMutating;
	const isInitialLoading =
		archivingQuery.isLoading || notificationSettingQuery.isLoading;
	const isDisabled = isLoading || isInitialLoading;

	const isArchivingAvailable = dataBox?.typSchranky !== 'PO';

	const showLargeMessageArchive = formDefinitions.formMethods.watch(
		'shouldArchiveAutomatically'
	);

	return {
		formDefinitions,
		onSubmit,
		isLoading,
		isDisabled,
		isInitialLoading,
		emailVerified,
		isArchivingAvailable,
		showLargeMessageArchive,
	};
};
